import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import LoadingButton from "../utils/LoadingButton";
import Loading from "../utils/Loading";
import Table from '../utils/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../utils/TableCell';
import Box from "../utils/Box";
import KeyRemoveIcon from 'mdi-react/KeyRemoveIcon';
import Title from "../utils/Title";
import TableCellButton from "../utils/TableCellButton";
import { formatDateTime } from "../../utils";


const styles = theme => ({
})

class UserAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            users: [],
            loading_list: true,
            loading_email: false
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.getUserList();
    }

    onChange(e) {
        e.persist();
        this.setState(state => ({
            ...state,
            [e.target.name]: e.target.value
		}));
    }

    removeUserAccess(email) {
        this.setState(state => ({
            ...state,
            loading_list: true,
        }), () => this.context.request({
            url: "/user/removeuseraccess",
            method: "POST",
            body: {
                email: email
            }
        })
        .then(() => this.getUserList())
        .catch(() => null))
    }

    getUserList() {
        this.setState(state => ({
            ...state,
            loading_list: true,
        }), () => this.context.request({
            url: "/user/usersaccess",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            users: response,
            loading_list: false,
        })))
        .catch(() => null))
    }

    sendUserAccess() {
        this.setState(state => ({
            ...state,
            loading_email: true,
        }), () => this.context.request({
            url: "/user/senduseraccess",
            method: "POST",
            body: {
                email: this.state.email
            }
        })
        .then(() => this.setState(state => ({
            ...state,
            loading_email: false,
        }), () => this.getUserList()))
        .catch(() =>  this.setState(state => ({
            ...state,
            loading_email: false,
        }))))
    }

	render() {
        // const { classes } = this.props;

		return (
        <Grid container justify={"center"} spacing={24}>
            <Grid item xs={12}>
                <Box>
                    <Title>Gerenciar acesso</Title>
                    <Typography gutterBottom>Aqui você pode gerenciar os e-mails que têm acesso ao Click Web de seu empreendimento, adicionando novos acessos ou removendo algum e-mail indesejado.</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box>
                    {this.state.loading_list || !this.state.users.length ? <Loading height={200} /> : (
                    <Fragment>
                        <Typography color="primary" variant="h5" gutterBottom>Lista de usuários</Typography>
                        <Typography gutterBottom>
                            Abaixo estão listados todos os e-mails que têm acesso ao seu Click Web.
                            Caso exista algum e-mail indesejado, você pode remover o acesso clicando no botão de remoção.
                        </Typography>
                        <Typography gutterBottom>
                            <b>ATENÇÃO:</b> As solicitações de acesso que ainda não foram aceitas não estão sendo listadas.
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Último login</TableCell>
                                    <TableCell align="center">Remover<br/>acesso</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.users.map((item, key) => (
                                <TableRow key={key}>
                                    <TableCell bold>{item.email}</TableCell>
                                    <TableCell>{item.last_activity ? formatDateTime(item.last_activity) : "Nunca conectou"}</TableCell>
                                    <TableCellButton
                                        onClick={() => this.removeUserAccess(item.email)}
                                        disabled={item.admin === 1}
                                        icon={KeyRemoveIcon}
                                        tooltip={"Remover acesso do usuário"}
                                    />
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Fragment>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box>
                    <Typography color="primary" variant="h5" gutterBottom>Adicionar acesso</Typography>
                    <Typography gutterBottom>Você pode dar acesso ao seu Click Web à qualquer e-mail que desejar.</Typography>
                    <Typography gutterBottom>Preencha o campo abaixo com o e-mail desejado e será enviado uma mensagem de boas vindas explicando o procedimento para acesso ao Click Web.</Typography>
                    <form>
                        <FormControl margin="dense" fullWidth>
                            <TextField
                                label="E-mail a ser adicionado"
                                type="email"
                                name="email"
                                onChange={this.onChange}
                                value={this.state.email}
                            />
                        </FormControl>
                        <FormControl margin="dense" fullWidth>
                            <LoadingButton
                                variant="contained"
                                size="large"
                                color="primary"
                                loading={this.state.loading_email}
                                onClick={() => this.sendUserAccess()}
                            >
                                Enviar solicitação
                            </LoadingButton>
                        </FormControl>
                    </form>
                </Box>
            </Grid>
        </Grid>
		);
    }
}

UserAccess.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

UserAccess.contextType = context;

export default withStyles(styles, {withTheme: true})(UserAccess);
