import React, { Component } from "react";
import PropTypes from 'prop-types';
import context from "../../../context";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Subtitle from "../../utils/Subtitle";
import Loading from "../../utils/Loading";
import { formatMoney } from "../../../utils";
import LineChart from "../../utils/LineChart";
import PieChart from '../../utils/PieChart';
import EmptyState from '../../utils/EmptyState';
import RoomServiceIcon from 'mdi-react/RoomServiceIcon';
import Table from '../../utils/Table';
import TableCell from '../../utils/TableCell';
import {TableHead, TableBody, TableRow} from '@material-ui/core';

class ServiceTax extends Component {

    constructor(props) {
        super(props);
        this.state = {
			data: null,
			loading: true
		};
    }


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.match.params.from !== this.props.match.params.from ||
            nextProps.match.params.to !== this.props.match.params.to ||
            nextProps.match.params.groupby !== this.props.match.params.groupby
        ) {
            this.load(
                nextProps.match.params.from,
                nextProps.match.params.to,
                nextProps.match.params.groupby
            );
        }
    }

    componentDidMount() {
        this.load(
            this.props.match.params.from,
            this.props.match.params.to,
            this.props.match.params.groupby
        );
    }

    load(from, to, groupby) {
        this.setState(state => ({
			...state,
			loading: true,
			data: null
        }), () => this.context.request({
            url: `/salessummary/servicetax/${from}/${to}/${groupby}`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
			loading: false,
			data: response
        })))
        .catch(error => null))
    }

    render() {
		return (
        <Box>
			<Subtitle>Taxa de serviço</Subtitle>
			{this.state.loading ? <Loading /> : this.state.data.sellers.length === 0 ? (
				<EmptyState icon={RoomServiceIcon}>
					Não foram pagas taxas de serviço durante este período
				</EmptyState>
			) :(
			<div>
				<PieChart
					data={this.state.data.sellers.map(seller => ({
						name: seller.name,
						value: seller.value 
					}))}
					dataKey="value"
					nameKey="name"
				/>
				<LineChart
					onlyPositive
					xValues={this.state.data.dates}
					dataSet={this.state.data.sellers.map(seller => ({
						legend: seller.name,
						values: seller.data
					}))}
				/>
				<Table height={250}>
					<TableHead>
						<TableRow>
							<TableCell>Garçom</TableCell>
							<TableCell>Valor</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.data.sellers.map((seller, index) => (
						<TableRow key={index}>
							<TableCell noWrap bold>{seller.name}</TableCell>
							<TableCell noWrap>{formatMoney(seller.value)}</TableCell>
						</TableRow>
						))}
						<TableRow>
							<TableCell noWrap bold>
								Total
							</TableCell>
							<TableCell noWrap bold>
								{formatMoney(this.state.data.sellers.reduce((tot, seller) => tot+seller.value, 0))}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
            )}
        </Box>
        );
    }
}

ServiceTax.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

ServiceTax.contextType = context;

export default withRouter(ServiceTax);
