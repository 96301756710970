const routes = {

    ROOT: '/',
    LOGIN: '/session',
    SEND_PASSWORD_RECOVER: '/session/sendpasswordrecover',
    PASSWORD_RECOVER: '/session/passwordrecover',    
    NEW_USER: '/session/newuser',    
    LOGOUT: '/session/logout',

    NAV: '/app',
    DASHBOARD: '/app/dashboard',
    BILLETS: '/app/billets',
    SERVICE_ORDERS: '/app/serviceorders',
    CHANGE_PASSWORD: '/app/changepassword',
    USER_ACCESS: '/app/useraccess',

    MANUAL: '/app/manual',

    REPORTS: '/app/reports',

    REPORT_CASH_CONTROL: '/app/reports/cashcontrol',
    REPORT_SHOPPING_LIST: '/app/reports/shoppinglist',
    REPORT_PRODUCT: barcode => '/app/reports/product/' + (barcode ? barcode : ':barcode'),
    REPORT_PRODUCT_LIST: '/app/reports/productlist',
    REPORT_CASHBOOK_FLOW: '/app/reports/cashbookflow',
    REPORT_BILLS_TO_RECEIVE: '/app/reports/billstoreceive',
    REPORT_BILLS_TO_PAY: '/app/reports/billstopay',
    REPORT_DOCUMENTS: '/app/reports/documents',
    REPORT_SALES_SUMMARY: '/app/reports/salesummary',
}

export default routes;