import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import EmoticonNeutralOutlineIcon from "mdi-react/EmoticonNeutralOutlineIcon";
import Loading from "../../utils/Loading";
import { ListItem, Chip, Avatar, Divider, ListItemText, Typography } from "@material-ui/core";
import Breadcrumbs from '../../utils/Breadcrumbs';
import { formatMoneyCompact, formatAmount } from "../../../utils";
import EmptyState from "../../utils/EmptyState";
import PagedList from "../../utils/PagedList";
import routes from "../../../routes";
import BarcodeIcon from 'mdi-react/BarcodeIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import CurrencyUsdOffIcon from 'mdi-react/CurrencyUsdOffIcon';
import WarehouseIcon from 'mdi-react/WarehouseIcon';

const styles = theme => ({
    icon: {
        color: theme.palette.grey.A700
    },
    primary: {
        color: theme.palette.primary.main 
    },
    chip: {
        marginRight: "5px",
        marginBottom: "5px"
    }
})

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.method !== this.props.match.params.method || nextProps.match.params.query !== this.props.match.params.query) {
            this.load()
        }
    }
    
    load() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: `/productlist/search/${this.props.match.params.method}/${this.props.match.params.query}`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    render() {
        const { classes } = this.props;
        return (
        <div>
            <Breadcrumbs
                items={[
                    {
                        label: "Grupos",
                        link: routes.REPORT_PRODUCT_LIST
                    },
                    {
                        label: `Resultados de pesquisa para "${this.props.match.params.query}"`,
                        active: true
                    }
                ]} 
            />
            {this.state.loading ? <Loading height={250} /> : this.state.data.length === 0 ? (
            <EmptyState icon={EmoticonNeutralOutlineIcon}>
                Nenhum resultado para "{this.props.match.params.query}"
            </EmptyState>
            ) : (
            <PagedList>
                {this.state.data.map((prod, index) => (
                    <React.Fragment key={index}>
                        <ListItem
                            button
                            className={classes.listItem}
                            component={RouterLink}
                            to={routes.REPORT_PRODUCT(prod.barcode)}
                            key={index}
                        >
                            <ListItemText
                                primary={(
                                <Typography noWrap>
                                    <Chip
                                        className={classes.chip} 
                                        color="primary"
                                        label={prod.barcode}
                                        avatar={<Avatar><BarcodeIcon /></Avatar>}
                                    />
                                    {prod.name}
                                </Typography>
                                )}
                                secondary={(
                                <Typography noWrap>
                                    <Chip
                                        className={classes.chip} 
                                        color="primary"
                                        variant="outlined"
                                        label={formatMoneyCompact(prod.value)}
                                        avatar={<Avatar><CurrencyUsdIcon /></Avatar>}
                                    />
                                    <Chip
                                        className={classes.chip} 
                                        color="primary"
                                        variant="outlined"
                                        label={formatMoneyCompact(prod.cost)}
                                        avatar={<Avatar><CurrencyUsdOffIcon /></Avatar>}
                                    />
                                    <Chip
                                        className={classes.chip} 
                                        variant="outlined"
                                        color={prod.control_storage ? "primary" : "secondary"}
                                        label={`Est.: ${prod.control_storage ? formatAmount(prod.storage) : "-"}`}
                                        avatar={<Avatar><WarehouseIcon /></Avatar>}
                                    />
                                </Typography>
                                )}
                            />
                        </ListItem>
                        <Divider component="li" />
                    </React.Fragment>
                ))}
                </PagedList>
            )}
        </div>
        );
    }
}

Search.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Search.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Search));
