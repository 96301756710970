import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom'

const styles = theme => ({
    container: {
        textAlign: 'center'
    },
    subtext: {
        fontSize: "10px"
    },
    button: {
        minHeight: "130px"
    }
})

class SectionButton extends Component {
	render() {
        const { classes } = this.props;
		return (
        <Button className={classes.button} fullWidth variant="contained" color="primary" component={RouterLink} to={this.props.link}>
            <div className={classes.container}>
                <div>{this.props.label}</div>
                <div className={classes.subtext}>{this.props.products} produtos</div>
            </div>  
        </Button>
        );
	}
}

SectionButton.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(SectionButton);
