import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom'


const styles = theme => ({
    container: {
        backgroundColor: theme.palette.primary.main + " !important",
        padding: "20px 5px",
        flexGrow: 1,
        cursor: "pointer",
    },
    icon: {
        textAlign: "center",
        color: "#FFFFFF !important",

    },
    title: {
        textAlign: "center",
        color: "#FFFFFF !important",
    }
})

class ReportItem extends Component {
	render() {
		const { classes } = this.props;
		return (
        <Grid item xs={6} sm={4} lg={3} xl={3}>
            <RouterLink style={{ textDecoration: 'none' }} to={this.props.path}>
                <Paper className={classes.container}>
                    <div className={classes.icon}><this.props.icon size={100} /></div>
                    <Typography noWrap className={classes.title}>{this.props.title}</Typography>
                </Paper>
            </RouterLink>
        </Grid>
		);
	}
}

ReportItem.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ReportItem);
