import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Session from './components/session/Session';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import Context from './context';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from "prop-types";
import config from './config';
import Nav from './components/nav/Nav';
import routes from './routes';


class App extends Component {

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);

		this.pushMessage = this.pushMessage.bind(this);
		this.pushHistory = this.pushHistory.bind(this);
		this.request = this.request.bind(this);
		this.onCloseSnackbar = this.onCloseSnackbar.bind(this);

		this.state = {
			message: '',
			pushHistory: this.pushHistory,
			request: this.request,
			pushMessage: this.pushMessage,
			offline: false
		}
	}

	pushMessage(message) {
		this.setState(state => ({
			...state,
			message: message,
		}));
	}

	pushHistory(url) {
		this.props.history.push(url);
	}

	request(data) {
		return new Promise((resolve, reject) => {
			let error = false;
			let token = localStorage.getItem("token");
			let server = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? config.server.dev : config.server.production
			fetch((data.raw ? "" : server) + data.url, {
				method: data.method,
				headers: new Headers({
					'Content-Type':'application/json',
					'Accept': 'application/json',
					'Authorization': token !== "null" && token !== "" && token !== null ? "Bearer " + token : ""
				}),
				body: JSON.stringify(data.body)
			})
			.then(response => {
				if (!response.ok) {
					error = true;
					if (response.status === 401) {
						this.pushHistory("/");
					}
				}
				return response.json();
			})
			.then(json => {
				if (json.a21354321dfbc28f0cb17b995bb9ca) {
					this.setState(state => ({
						...state,
						offline: true
					}), () => {
						reject();
					})
				} else {
					if (this.state.offline) {
						this.setState(state => ({
							...state,
							offline: false
						}))
					}
					if (json.message) {
						this.pushMessage(json.message);
					}
					if (error) {
						reject(json);
					} else {
						resolve(json);
					}
				}
			})
			.catch(err => {
				this.pushMessage("Erro interno do servidor");
				reject(err);
			})
		});
	}

	onCloseSnackbar() {
		if (this.state.message !== '') {
			this.setState(state => ({
				...state,
				message: ''
			}));
		}
	}

	render() {
		return (
		<Context.Provider value={this.state}>
			<MuiThemeProvider theme={theme}>
				<Switch>
					<Route exact path={routes.ROOT} render={() => <Redirect to={routes.LOGIN} />} />
					<Route path={routes.LOGIN} component={Session}/>
					<Route path={routes.NAV} component={Nav}/>
				</Switch>
				{this.snackbar()}
				{this.offlinebar()}
			</MuiThemeProvider>
		</Context.Provider>
		);
	}

	offlinebar() {
		return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={this.state.offline}
			ClickAwayListenerProps={{
				onClickAway: () => null
			}}
			transitionDuration={{
				enter: 200,
				exit: 200
			}}
			message={<span>Você está offline</span>}
		/>
		)
	}

	snackbar() {
		return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={this.state.message !== ''}
			autoHideDuration={3000}
			onClose={this.onCloseSnackbar}
			ClickAwayListenerProps={{
				onClickAway: () => null
			}}
			transitionDuration={{
				enter: 200,
				exit: 200
			}}
			message={<span>{this.state.message}</span>}
		/>		
		);
	}

}


export default withRouter(App);
