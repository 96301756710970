import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from 'react-router-dom'
import LoadingButton from "../utils/LoadingButton";
import context from "../../context";
import routes from "../../routes";

const styles = theme => ({});


class SendPasswordRecover extends Component {

	constructor(props) {
		super(props);
		this.state = {
            email: '',
            loading: false,
		}
        this.onChange = this.onChange.bind(this);
        this.sendPasswordRecover = this.sendPasswordRecover.bind(this);
	}

	onChange(e) {
        e.persist();
        this.setState(state => ({
            ...state,
            [e.target.name]: e.target.value
		}));
    }
    
    sendPasswordRecover() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/user/sendpasswordrecover",
            method: "POST",
            body: {
                email: this.state.email
            }
        })
        .finally(() => this.setState(state => ({
            ...state,
            loading: false,
        }))))
    }

	render() {
		return (
        <form style={{padding: "15px"}}>
            <Typography variant="h5" color="primary">
                Recuperar sua senha
            </Typography>
            <FormControl fullWidth={true} margin="dense">
                <TextField
                    label="E-mail"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                />
            </FormControl>
            <FormControl fullWidth={true} margin="dense">
                <Link component={RouterLink} to={routes.LOGIN}>
                    Voltar para tela de login
                </Link>
            </FormControl>
            <FormControl fullWidth={true} margin="dense">
                <LoadingButton
                    loading={this.state.loading}
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={this.sendPasswordRecover}
                >
                    Recuperar senha
                </LoadingButton>
            </FormControl>
        </form>
		);
	}
}

SendPasswordRecover.contextType = context;

export default withStyles(styles, { withTheme: true } )(SendPasswordRecover);
