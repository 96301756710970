import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import License from "./License";
import Sync from "./Sync";
import Fiscal from "./Fiscal";


const styles = theme => ({

})

class Dashboard extends Component {
	render() {
		return (
        <Grid container spacing={24}>
            <Grid item xs={12} sm={6} lg={4}>
                <License />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Sync />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Fiscal />            
            </Grid>
        </Grid>
		);
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Dashboard.contextType = context;

export default withStyles(styles, {withTheme: true})(Dashboard);
