import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loading from "../utils/Loading";
import IconButton from '@material-ui/core/IconButton';
import Box from "../utils/Box";
import ClipboardOutlineIcon from 'mdi-react/ClipboardOutlineIcon';
import AccountTieIcon from 'mdi-react/AccountTieIcon';
import ClipboardCheckOutlineIcon from 'mdi-react/ClipboardCheckOutlineIcon';
import CalendarClockIcon from 'mdi-react/CalendarClockIcon';
import CalendarEditIcon from 'mdi-react/CalendarEditIcon';
import AccountEditIcon from 'mdi-react/AccountEditIcon';
import AlarmLightIcon from 'mdi-react/AlarmLightIcon';
import CalendarImportIcon from 'mdi-react/CalendarImportIcon';
import DrawIcon from 'mdi-react/DrawIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import EmptyState from "../utils/EmptyState";
import { formatDateTime } from "../../utils";
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from "@material-ui/core";
import Title from "../utils/Title";
import PagedList from "../utils/PagedList";

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
    dialogTitle: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
})

class ServiceOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orders: [],
            modal_visible: false,
            selected: -1
        };
    }

    openServiceOrder(index) {
        this.setState(state => ({
            ...state,
            modal_visible: true,
            selected: index
        }));
    }

    closeModal() {
        this.setState(state => ({
            ...state,
            modal_visible: false
        }))
    }

    componentDidMount() {
        this.getServiceOrders();
    }

    formatReport(str) {
        return str.split("\r").join("; ");
    }

    getServiceOrders() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/enterprise/serviceorders",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            orders: response,
        })))
        .catch(() => null))
    }

	render() {
        const { classes } = this.props;
		return (
        <Fragment>
            <Grid container justify={"center"} spacing={24}>
                <Grid item xs={12}>
                    <Box>
                        <Title>Ordens de serviço</Title>
                        <Typography style={{ marginBottom: "20px" }}>As ordens de serviço aqui listadas são as efetuadas após o dia 26 de outubro de 2018, quando foi realizada a conversão para o sistema digital de ordens de serviço.</Typography>
                        {this.state.loading ? <Loading height={300} /> : (
                            this.state.orders.length ? (
                            <PagedList>
                                {this.state.orders.map((order, key) => (
                                <React.Fragment>
                                    <ListItem button onClick={() => this.openServiceOrder(key)}>
                                        <ListItemAvatar>
                                            <Avatar className={classes.avatar}>
                                                <ClipboardOutlineIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={`${formatDateTime(order.date)} - ${order.reason}`}
                                            secondary={`Assinado por: ${order.subscriber}; Técnico designado: ${order.technical}`}
                                        />
                                    </ListItem>
                                    {key === this.state.orders.length-1 || <Divider />}
                                </React.Fragment>
                                ))}
                            </PagedList>
                            ) : (
                            <EmptyState icon={ClipboardOutlineIcon} >
                                Você não possui ordens de serviço registradas
                            </EmptyState>
                            )
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={this.state.modal_visible}
                onClose={() => this.closeModal()}
            >
                <DialogTitle className={classes.dialogTitle} onClose={() => this.closeModal()}>
                    Ordem de serviço
                    <IconButton className={classes.closeButton} onClick={() => this.closeModal()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {this.state.selected === -1 ? null : (
                    <div style={{overflowX: "auto", maxWidth: "100%"}}>
                        <List>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <AlarmLightIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Motivo da visita`}
                                        secondary={`${this.formatReport(this.state.orders[this.state.selected].reason)}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <CalendarClockIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Data agendada`}
                                        secondary={`${formatDateTime(this.state.orders[this.state.selected].date)}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <CalendarImportIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Data chegada`}
                                        secondary={`${formatDateTime(this.state.orders[this.state.selected].arrival)}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <CalendarEditIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Data saída`}
                                        secondary={`${formatDateTime(this.state.orders[this.state.selected].exit)}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <AccountTieIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Técnico`}
                                        secondary={`${this.state.orders[this.state.selected].technical}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <ClipboardCheckOutlineIcon />
                                        </Avatar>  
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Relatório técnico`}
                                        secondary={`${this.formatReport(this.state.orders[this.state.selected].report)}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <React.Fragment>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <AccountEditIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`Responsável`}
                                        secondary={`${this.state.orders[this.state.selected].subscriber}`}
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <DrawIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`Assinatura do responsável`}
                                    secondary={(
                                    <React.Fragment>
                                        <div style={{maxWidth: "100%", overflowX: "auto"}}>
                                            <div className="signature" dangerouslySetInnerHTML={{ __html: atob(Buffer.from(this.state.orders[this.state.selected].signature, 'base64').toString()) }} />
                                        </div>
                                    </React.Fragment>
                                    )}
                                />
                            </ListItem>
                        </List>
                    </div>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
		);
    }
}

ServiceOrders.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

ServiceOrders.contextType = context;

export default withStyles(styles, {withTheme: true})(ServiceOrders);
