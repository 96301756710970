import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import HelpIcon from 'mdi-react/HelpIcon'
import EmptyState from "../../utils/EmptyState";
import Header from "./Header";
import Sales from "./Sales";

const styles = theme => ({
})

class ProductInfo extends Component {
    render() {
        return (
        <Grid container justify={"center"} spacing={24}>
            {!this.props.match.params.barcode ? (
            <Grid item xs={12}>
                <Box>
                    <EmptyState icon={HelpIcon}>
                        Produto não encontrado
                    </EmptyState>
                </Box>
            </Grid>
            ) : (
            <Fragment>
                <Header barcode={this.props.match.params.barcode} />
                <Sales barcode={this.props.match.params.barcode} />
            </Fragment>
            )}
        </Grid>
        );
    }
}

ProductInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

ProductInfo.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(ProductInfo));
