import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Loading from "../../utils/Loading";
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../utils/TableCell';
import Box from "../../utils/Box";
import {  formatMoney, formatDateTime } from "../../../utils";
import Table from "../../utils/Table";

const styles = theme => ({
})

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    load(control) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashcontrol/header/"+control,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response,
        })))
        .catch(() => null))
    }

	render() {

		return (
        <Box>
            {this.state.loading ? <Loading height={250} /> : (
            <Fragment>
                <Typography variant="h5" color="primary" gutterBottom>Detalhes do caixa {this.state.data.local_id}</Typography>
                <Table maxHeight={300}>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Número</TableCell>
                            <TableCell align="right">{this.state.data.local_id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Sincronização</TableCell>
                            <TableCell align="right">{formatDateTime(this.state.data.sync_date)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Abertura</TableCell>
                            <TableCell align="right">{formatDateTime(this.state.data.opening_date)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Fechamento</TableCell>
                            <TableCell align="right">{formatDateTime(this.state.data.closure_date)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Total de vendas</TableCell>
                            <TableCell align="right">{formatMoney(this.state.data.sales)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Troco inicial</TableCell>
                            <TableCell align="right">{formatMoney(this.state.data.initial_change)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Quebra de caixa</TableCell>
                            <TableCell align="right">{formatMoney(this.state.data.cash_break)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right" minWidth bold>Terminal</TableCell>
                            <TableCell align="right">{this.state.data.terminal}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
            )}
        </Box>
		);
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Header.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Header));
