import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Typography from '@material-ui/core/Typography';
import Loading from "../../utils/Loading";
import TableBody from '@material-ui/core/TableBody';
import { withRouter } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../utils/TableCell';
import Box from "../../utils/Box";
import PieChart from "../../utils/PieChart";
import {  formatMoney, paymentTypes, COLORS } from "../../../utils";
import Table from "../../utils/Table";
import { TableHead, Grid } from "@material-ui/core";
import EmptyState from "../../utils/EmptyState";
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import SubdirectoryArrowRightIcon from 'mdi-react/SubdirectoryArrowRightIcon';

const styles = theme => ({
    icon: {
        color: theme.palette.grey.A700
    },
    primary: {
        color: theme.palette.primary.main 
    }
})

class SalesPerPaymentMethod extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    load(control) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashcontrol/salesperpaymentmethod/"+control,
            method: "GET"
        })
        .then(response => this.setState(state => {
            state.loading = false;
            state.data = this.parseResponse(response);
            state.chart = this.parseChart(state.data);
            return state;
        })).catch(() => null))
    }


    parseChart(data) {
        return data.reduce((acc, type, index) => acc.concat(type.methods.map(el => ({
            description: el.payment_method,
            total: el.value,
            color: COLORS[index % COLORS.length]
        }))), []);
    }

    parseResponse(response) {
        return response.map((type, index) => ({
            ...type,
            icon: paymentTypes.find(el => el.id === type.type_id).icon,
            color: COLORS[index % COLORS.length]
        }));
    }

	render() {
        const { classes } = this.props;
		return (
        <Box>
            {this.state.loading ? <Loading height={250} /> : (
            <Fragment>
                <Typography variant="h5" color="primary" gutterBottom>
                    Vendas por forma de pagamento
                </Typography>
                
                {this.state.data.length ? (
                <div>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <PieChart 
                                data={this.state.data}
                                dataKey="total"
                                nameKey="description"
                                colorKey="color"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <PieChart 
                                data={this.state.chart}
                                dataKey="total"
                                nameKey="description"
                                colorKey="color"
                            />
                        </Grid>
                    </Grid>
                    <Table maxHeight={300}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} align="center">Método</TableCell>
                                <TableCell align="center">Autorizado</TableCell>
                                <TableCell align="center">Não autorizado</TableCell>
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.map((type, type_key) => (
                            <Fragment key={type_key}>
                                <TableRow>
                                    <TableCell className={classes.primary} variant="head" minWidth>
                                        <type.icon />
                                    </TableCell>
                                    <TableCell className={classes.primary} variant="head" bold>
                                        TOTAL {type.description}
                                    </TableCell>
                                    <TableCell className={classes.primary} variant="head" align="right" minWidth bold>
                                        {formatMoney(type.emitted)}
                                    </TableCell>
                                    <TableCell className={classes.primary} variant="head" align="right" minWidth bold>
                                        {formatMoney(type.non_emitted)}
                                    </TableCell>
                                    <TableCell className={classes.primary} variant="head" align="right" minWidth bold>
                                        {formatMoney(type.total)}
                                    </TableCell>
                                </TableRow>
                                {type.methods.map((method, key) => (
                                <TableRow key={key}>
                                    <TableCell minWidth><SubdirectoryArrowRightIcon className={classes.icon} /></TableCell>
                                    <TableCell>{method.payment_method}</TableCell>
                                    <TableCell align="right" minWidth>{formatMoney(method.emitted)}</TableCell>
                                    <TableCell align="right" minWidth>{formatMoney(method.value - method.emitted)}</TableCell>
                                    <TableCell align="right" minWidth>{formatMoney(method.value)}</TableCell>
                                </TableRow>
                                ))}
                            </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                ) : (
                <EmptyState icon={InformationOutlineIcon}>
                    Não foram realizadas vendas neste caixa
                </EmptyState>
                )}
            </Fragment>
            )}
        </Box>
		);
    }
    
}

SalesPerPaymentMethod.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

SalesPerPaymentMethod.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(SalesPerPaymentMethod));
