import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from 'prop-types';


const styles = theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
})

class Loading extends Component {
	render() {
		const { classes } = this.props;

		return (
        <div className={classes.container} style={{height: this.props.height + "px"}}>
            <CircularProgress  disableShrink />
        </div>
		);
	}
}

Loading.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Loading);
