import React, { Component } from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import context from "../../context";
import ViewDashboardOutlineIcon from 'mdi-react/ViewDashboardOutlineIcon';
import BookOpenPageVariantIcon from 'mdi-react/BookOpenPageVariantIcon';
import BarcodeIcon from 'mdi-react/BarcodeIcon';
import ClipboardOutlineIcon from 'mdi-react/ClipboardOutlineIcon';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import LockIcon from 'mdi-react/LockIcon';
import LogoutVariantIcon from 'mdi-react/LogoutVariantIcon';
import FinanceIcon from 'mdi-react/FinanceIcon';
import { Link as RouterLink } from 'react-router-dom'
import routes from "../../routes";
import Loading from "../utils/Loading";



class Sidebar extends Component {
	render() {
        const links = [
            {
                path: routes.DASHBOARD,
                label: "Dashboard",
                icon: ViewDashboardOutlineIcon
            },
            {
                path: routes.BILLETS,
                label: "Boletos em aberto",
                icon: BarcodeIcon,
            },
            {
                path: routes.SERVICE_ORDERS,
                label: "Ordens de serviço",
                icon: ClipboardOutlineIcon
            },
            {
                path: routes.REPORTS,
                label: "Relatórios",
                icon: FinanceIcon
            },
            {
                path: routes.MANUAL,
                label: "Manual Click",
                icon: BookOpenPageVariantIcon
            },
            {
                path: routes.USER_ACCESS,
                label: "Gerenciar acesso",
                icon: AccountKeyIcon,
                adminOnly: true
            },
            {
                path: routes.CHANGE_PASSWORD,
                label: "Alterar senha",
                icon: LockIcon,
            },
            {
                path: routes.LOGOUT,
                label: "Sair do Click Web",
                icon: LogoutVariantIcon,
            },
        ];

		return (
        this.props.loading ? <Loading height={100} /> : 
        <List>
            <React.Fragment>
            {links.map((item, index) => !(!item.adminOnly || this.props.admin) || (
            <ListItem
                component={RouterLink}
                to={item.path}
                onClick={() => this.props.onClose || null}
                button key={index}
            >
                <ListItemIcon><item.icon /></ListItemIcon>
                <ListItemText primary={item.label} />
            </ListItem>
            ))}
            </React.Fragment>
        </List>
		);
    }
}


Sidebar.contextType = context;

export default Sidebar;
