import React, { Component } from "react";
import PropTypes from 'prop-types';
import context from "../../../context";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Subtitle from "../../utils/Subtitle";
import EmptyState from "../../utils/EmptyState";
import Loading from "../../utils/Loading";
import { formatMoney, formatAmount } from "../../../utils";
import LineChart from "../../utils/LineChart";
import Table from '../../utils/Table';
import TableCell from '../../utils/TableCell';
import {TableHead, TableBody, TableRow} from '@material-ui/core';
import MusicIcon from 'mdi-react/MusicIcon';

class Couvert extends Component {

    constructor(props) {
        super(props);
        this.state = {
			data: null,
			loading: true
		};
    }


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.match.params.from !== this.props.match.params.from ||
            nextProps.match.params.to !== this.props.match.params.to ||
            nextProps.match.params.groupby !== this.props.match.params.groupby
        ) {
            this.load(
                nextProps.match.params.from,
                nextProps.match.params.to,
                nextProps.match.params.groupby
            );
        }
    }

    componentDidMount() {
        this.load(
            this.props.match.params.from,
            this.props.match.params.to,
            this.props.match.params.groupby
        );
    }

    load(from, to, groupby) {
        this.setState(state => ({
			...state,
			loading: true,
			data: null
        }), () => this.context.request({
            url: `/salessummary/couvert/${from}/${to}/${groupby}`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
			loading: false,
			data: response
        })))
        .catch(error => null))
    }

    render() {
		return (
        <Box>
			<Subtitle>Couvert artístico</Subtitle>
			{this.state.loading ? <Loading /> : this.state.data.couvert.reduce((acc, el) => acc + el.amount, 0) === 0 ? (
				<EmptyState icon={MusicIcon}>
					Não foram comercializados couverts artísticos neste período
				</EmptyState>
			) : (
			<div>
				<LineChart
					onlyPositive
					xValues={this.state.data.dates}
					dataSet={[{
						legend: "Couvert artístico",
						values: this.state.data.couvert.map(el => el.value)
					}]}
				/>
				<Table height={250}>
					<TableHead>
						<TableRow>
							<TableCell>Data</TableCell>
							<TableCell>Qtd.</TableCell>
							<TableCell>Valor</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.data.couvert.map((day, index) => (
						<TableRow key={index}>
							<TableCell noWrap bold>{this.state.data.dates[index]}</TableCell>
							<TableCell noWrap>{formatAmount(day.amount)}</TableCell>
							<TableCell noWrap>{formatMoney(day.value)}</TableCell>
						</TableRow>
						))}
						<TableRow>
							<TableCell noWrap bold>
								Total
							</TableCell>
							<TableCell noWrap bold>
								{formatAmount(this.state.data.couvert.reduce((tot, day) => tot+day.amount, 0))}
							</TableCell>
							<TableCell noWrap bold>
								{formatMoney(this.state.data.couvert.reduce((tot, day) => tot+day.value, 0))}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
            )}
        </Box>
        );
    }
}

Couvert.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Couvert.contextType = context;

export default withRouter(Couvert);
