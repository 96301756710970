import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import { withRouter } from 'react-router-dom';
import Loading from "../../utils/Loading";
import Breadcrumbs from "../../utils/Breadcrumbs";
import SectionButton from "./SectionButton";
import routes from "../../../routes";
import { Grid } from "@material-ui/core";

const styles = theme => ({
    icon: {
        color: theme.palette.grey.A700
    },
    primary: {
        color: theme.palette.primary.main 
    }
})

class Subgroups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        this.load();
    }
    
    load() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: `/productlist/groups/${this.props.match.params.groupid}/subgroups`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    render() {
        return (
        <div>
            <Breadcrumbs
                items={[
                    {
                        label: "Grupos",
                        link: routes.REPORT_PRODUCT_LIST
                    },
                    {
                        label: `Grupo "${this.state.data.group_description}"`,
                        active: true,
                        loading: this.state.loading
                    }
                ]} 
            />
            {this.state.loading ? <Loading height={250} /> : (
            <Grid container spacing={24}>
                {this.state.data.list.map((el, index) => 
                <Grid item xs={6} sm={4} md={4} lg={3} key={index}>
                    <SectionButton
                        link={`${routes.REPORT_PRODUCT_LIST}/${this.props.match.params.groupid}/subgroups/${el.id}/products`}
                        label={el.label}
                        products={el.products}
                    />
                </Grid>
                )}
            </Grid>
            )}
        </div>
        );
    }
}

Subgroups.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Subgroups.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Subgroups));
