import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const styles = theme => ({
    container: {
        textAlign: "center",
        color: theme.palette.primary.light,
        margin: "30px 0px"
    },
    text: {
        fontSize: "26px",
    }
})

class EmptyState extends Component {
	render() {
		const { classes } = this.props;
		return (
        <div className={classes.container}>
            <this.props.icon size={100} />
            <div className={classes.text}>
                {this.props.children}
            </div>
        </div>
		);
	}
}

EmptyState.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(EmptyState);
