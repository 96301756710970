import { createMuiTheme } from '@material-ui/core/styles';

const options = {
    mainColor: "#3ba7b5",
    secondaryColor: "#d32f2f"
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: options.mainColor,
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: options.secondaryColor,
            contrastText: "#FFFFFF"
        }
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiFormHelperText: {
            root: {
                color: options.mainColor,
                fontWeight: "bold"
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottomColor: options.mainColor,
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottomColor: options.mainColor,
                },
            },
        },
        MuiLink: {
            root: {
                textDecoration: "underline !important"
            }
        },
        MuiTableRow:{
            "selected": {
                backgroundColor: options.mainColor + "22 !important"
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                borderBottom: '1px solid rgba(0,0,0,.125)',
                marginBottom: -1,
                minHeight: 56,
                '&$expanded': {
                    minHeight: 56,
                },
            },
            content: {
                '&$expanded': {
                    margin: '12px 0',
                },
            },
            expanded: {},
        },
        MuiExpansionPanel: {
            root: {
                border: '1px solid rgba(0,0,0,.125)',
                boxShadow: 'none',
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
            },
            expanded: {
                margin: 'auto',
            },
        }
    }
})

export default theme;