import React, { Component } from "react";
import { BarChart as OriginalBarChart, Tooltip, Bar, CartesianGrid, XAxis, ResponsiveContainer } from 'recharts';
import { COLORS, formatAmount, formatMoney } from "../../utils";


class BarChart extends Component {
    processData() {
        if (this.props.dataSet.length) {
            var i, j;
			const response = this.props.xValues.map(v => ({ x: v }));
			for(i=0; i<this.props.dataSet.length; i++) {
                for(j=0; j<response.length; j++) {
					response[j]["y"+i] = this.props.dataSet[i].values[j];
					if (this.props.onlyPositive && response[j]["y"+i] < 0) response[j]["y"+i] = 0;
                }
            }
            return response;
        } else {
            return [];
        }
    }

	render() {
        const processedData = this.processData();
		return (
        <ResponsiveContainer width="100%" aspect={2}>
            <OriginalBarChart
                data={processedData}
                margin={{top: 4, left: 4, right: 4, bottom: 24}}
            >
                <XAxis dataKey="x" angle={20} tickMargin={10} />
                <Tooltip
                    formatter={this.props.amount ? formatAmount : formatMoney}
                    isAnimationActive={false}
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                {this.props.dataSet.map((data, dkey) => (
                    <Bar
                        key={dkey}
                        name={data.legend}
                        type="monotone"
                        dataKey={`y${dkey}`}
                        fill={data.color || COLORS[dkey % COLORS.length]}
                    />
                ))}
            </OriginalBarChart>
        </ResponsiveContainer>
		);
	}
}

export default BarChart;
