import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import { FormControl, TextField, Button, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import Box from "../../utils/Box";
import { withRouter, Switch, Route } from 'react-router-dom';
import Title from "../../utils/Title";
import routes from "../../../routes";
import Groups from './Groups';
import Subgroups from './Subgroups';
import Products from './Products';
import Search from './Search';

const styles = theme => ({
})

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            method: 'name',
            query: ''
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }

    search() {
        if (this.state.query.trim() !== "") {
            this.context.pushHistory(routes.REPORT_PRODUCT_LIST + `/search/${this.state.method}/${this.state.query}`)
        }
    }

    render() {
        return (
        <Grid container justify={"center"} spacing={24}>
            <Grid item xs={12}>
                <Box>
                    <Title>Pesquisar no catálogo de produtos</Title>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label={"Digite sua pesquisa"}
                            name={"query"}
                            type={"text"}
                            value={this.state.query}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl component="fieldset" margin="dense" fullWidth>
                        <RadioGroup
                            aria-label="method"
                            name="method"
                            value={this.state.method}
                            onChange={this.onChange}
                            row
                        >
                            <FormControlLabel value="name" control={<Radio color="primary" />} label="Pesquisar por nome do produto" />
                            <FormControlLabel value="code" control={<Radio color="primary" />} label="Pesquisar por código do produto" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <Button
                            onClick={() => this.search()}
                            variant="contained"
                            color="primary"
                        >
                            Pesquisar
                        </Button>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <Title>Navegação</Title>
                    <Switch>
                        <Route exact path={routes.REPORT_PRODUCT_LIST + '/:groupid/subgroups'} component={Subgroups} />
                        <Route exact path={routes.REPORT_PRODUCT_LIST} component={Groups} />
                        <Route exact path={routes.REPORT_PRODUCT_LIST + '/:groupid/subgroups/:subgroupid/products'} component={Products} />
                        <Route exact path={routes.REPORT_PRODUCT_LIST + '/search/:method/:query'} component={Search} />
                    </Switch>
                </Box>
            </Grid>
        </Grid>
        );
    }
}

ProductList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

ProductList.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(ProductList));
