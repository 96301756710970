import React, { Component } from "react";
import context from "../../../context";
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from "@material-ui/core";
import { formatMoney } from "../../../utils";

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
});


class Payment extends Component {

    render() {
        const { classes } = this.props;
        return (
        <ListItem>
            <ListItemAvatar>
                <Avatar className={classes.avatar}><this.props.icon /></Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={formatMoney(this.props.value)}
                secondary={this.props.description}
            />
        </ListItem>
        );
    }

}

Payment.contextType = context;

export default withStyles(styles, {withTheme: true})(Payment);