import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import routes from "../../../routes";
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Loading from "../../utils/Loading";
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../utils/TableCell';
import Box from "../../utils/Box";
import {  formatMoney, formatAmount } from "../../../utils";
import Table from "../../utils/Table";
import { TableHead, Link } from "@material-ui/core";
import EmptyState from "../../utils/EmptyState";
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import SubdirectoryArrowRightIcon from 'mdi-react/SubdirectoryArrowRightIcon';
import { Link as RouterLink } from 'react-router-dom'


const styles = theme => ({
    icon: {
        color: theme.palette.grey.A700
    },
    noPadding: {
        padding: "0px"
    },
})

class SalesPerProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
        this.load = this.load.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    load(control) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashcontrol/salesperproduct/"+control,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(error => null))
    }

	render() {
		return (
        <Box>
            {this.state.loading ? <Loading height={250} /> : (
            <Fragment>
                <Typography variant="h5" color="primary" gutterBottom>
                    Vendas por produto
                </Typography>
                {this.state.data.length ? (
                <Table maxHeight={300}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>Descrição</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell>Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((section, section_key) => (
                        <Fragment key={section_key}>
                            <TableRow>
                                <TableCell variant="head" bold colSpan={3}>
                                    <Link component={RouterLink} to={`${routes.REPORT_PRODUCT_LIST}/${section.local_id}/subgroups`}>
                                        {section.description}
                                    </Link>
                                </TableCell>
                                <TableCell variant="head" bold>{formatAmount(section.amount)}</TableCell>
                                <TableCell variant="head" bold>{formatMoney(section.value)}</TableCell>
                            </TableRow>
                            {section.subsections.map((subsection, subsection_key) => (
                            <Fragment key={subsection_key}>
                                <TableRow>
                                    <TableCell variant="head" bold minWidth><SubdirectoryArrowRightIcon /></TableCell>
                                    <TableCell variant="head" bold colSpan={2}>
                                        <Link component={RouterLink} to={`${routes.REPORT_PRODUCT_LIST}/${section.local_id}/subgroups/${subsection.local_id}/products`}>
                                            {subsection.description}
                                        </Link>
                                    </TableCell>
                                    <TableCell variant="head" bold minWidth>{formatAmount(subsection.amount)}</TableCell>
                                    <TableCell variant="head" bold minWidth>{formatMoney(subsection.value)}</TableCell>
                                </TableRow>
                                {subsection.products.map((product, product_key) => (
                                <TableRow key={product_key}>
                                    <TableCell minWidth></TableCell>
                                    <TableCell variant="head" minWidth><SubdirectoryArrowRightIcon /></TableCell>
                                    <TableCell>
                                        <Link component={RouterLink} to={routes.REPORT_PRODUCT(product.barcode)}>
                                            {product.barcode} - {product.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell minWidth>{formatAmount(product.amount)}</TableCell>
                                    <TableCell minWidth>{formatMoney(product.value)}</TableCell>
                                </TableRow>
                                ))}
                            </Fragment>
                            ))}
                        </Fragment> 
                        ))}
                    </TableBody>
                </Table>
                ) : (
                <EmptyState icon={InformationOutlineIcon}>
                    Não foram realizadas vendas neste caixa
                </EmptyState>
                )}
            </Fragment>
            )}
        </Box>
		);
    }
}

SalesPerProduct.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

SalesPerProduct.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(SalesPerProduct));
