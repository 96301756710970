import React from "react";
import { Grid } from "@material-ui/core";
import SimpleList from "./SimpleList";
import MonthAverage from "./MonthAverage";
import YearAverage from "./YearAverage";

class ShoppingList extends React.Component {
    render() {
        return (
        <Grid container spacing={24}>
            <Grid item xs={12}><SimpleList /></Grid>
            <Grid item xs={12}><MonthAverage /></Grid>
            <Grid item xs={12}><YearAverage /></Grid>
        </Grid>
        );
    }
}

export default ShoppingList;
