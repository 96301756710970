import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Chip } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom'


const styles = theme => ({
    container: {
        display: "inline-block"
    },
    root: {
        marginBottom: 2*theme.spacing.unit,
        overflow: "auto",
        whiteSpace: "nowrap"
    },
    separator: {
        fontWeight: "bold",
        padding: "0px 5px",
        color: "#999"
    }
});

class Breadcrumbs extends Component {
	render() {
		const { classes } = this.props;
		return (
        <div className={classes.root}>
            {this.props.items.map((el, index) => (
            <div key={index} className={classes.container}>
                {index !== 0 ? <span className={classes.separator}>›</span> : null}
                <Chip
                    color={el.active ? "primary" : "default"}
                    clickable={!el.active && !el.loading}
                    label={el.loading ? "Carregando..." : el.label}
                    component={el.active || el.loading ? "a" : RouterLink}
                    to={el.active || el.loading ? null : el.link}
                />
            </div>
            ))}
        </div>
		);
	}
}

Breadcrumbs.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Breadcrumbs);
