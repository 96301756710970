import React, { Component } from "react";
import { Pie, Cell, Sector, ResponsiveContainer, PieChart as OriginalPieChart } from 'recharts';
import { COLORS, formatMoney } from "../../utils";

class PieChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };
    }

    render() {
        const { ...others } = this.props;
        const margin = 20;
        return (
        <ResponsiveContainer width="100%" aspect={2}>
            <OriginalPieChart margin={{bottom: margin, left: margin, right: margin, top: margin}}>
                <Pie
                    activeIndex={this.state.activeIndex}
                    activeShape={this.renderActiveShape}
                    cx={"50%"}
                    cy={"50%"}
                    onMouseEnter={this.onPieEnter}
                    {...others}
                >
                    {this.props.data.map((e, i) => <Cell
                      key={i}
                      fill={this.props.colorKey ? e[this.props.colorKey] : COLORS[i % COLORS.length]}
                    />)}
                </Pie>
            </OriginalPieChart>
        </ResponsiveContainer>
        );
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    }

    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
          cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
          fill, payload, percent, value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 3) * cos;
        const sy = cy + (outerRadius + 3) * sin;
        const mx = cx + (outerRadius + 15) * cos;
        const my = cy + (outerRadius + 15) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 15;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const fontSize = 12;

        return (
          <g>
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 3}
              outerRadius={outerRadius + 7}
              fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} fontSize={fontSize} textAnchor={textAnchor} fill="#333">{`${payload[this.props.nameKey]}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 8} y={ey} fontSize={fontSize} dy={fontSize} textAnchor={textAnchor} fill="#999">
              {formatMoney(value) + " (" + (percent * 100).toFixed(2) + "%)"}
            </text>
          </g>
        );
      };
}


export default PieChart;
