import React, { Component } from "react";
import {  Chip, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListSubheader } from '@material-ui/core';
import context from "../../../context";
import Loading from "../../utils/Loading";
import QrcodeIcon from 'mdi-react/QrcodeIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import TableChairIcon from 'mdi-react/TableChairIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import RoomServiceIcon from 'mdi-react/RoomServiceIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import SigmaIcon from 'mdi-react/SigmaIcon';
import CartOutlineIcon from 'mdi-react/CartOutlineIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import { withStyles } from '@material-ui/core/styles';
import { formatDateTime, paymentTypes, formatMoney } from "../../../utils";
import Payment from './Payment';
import EmoticonSadOutlineIcon from 'mdi-react/EmoticonSadOutlineIcon';
import Product from './Product';
import Title from "../../utils/Title";
import EmptyState from "../../utils/EmptyState";
import routes from "../../../routes";
import { Link as RouterLink } from 'react-router-dom';

const styles = theme => ({
    chip: {
        marginLeft: "5px",
        marginBottom: "5px"
    },
    listAvatarSuccess: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    listAvatarError: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    list: {
        borderTop: "1px solid #DDD",
        margin: "5px 0px 0px 0px"
    }
});


class DocumentInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }


    load(id) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: `/documents/document/${id}`,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response.length ? response[0] : null
        }))).catch(() => null))
    }

    render() {
        const { classes } = this.props;
        return this.state.loading ? (
        <Loading height={300} />
        ) : this.state.data === null ? (
        <EmptyState icon={EmoticonSadOutlineIcon}>
            Documento não encontrado
        </EmptyState>
        ) : (
        <div>
            <Title>Documento nº {this.props.match.params.id}</Title>
            {this.state.data.table <= 0 ? null : (
            <Chip
                className={classes.chip}
                variant="outlined"
                label={`Mesa ${this.state.data.table}`}
                avatar={<Avatar><TableChairIcon /></Avatar>}
                color={"primary"}
            />
            )}
            {this.state.data.people <= 0 ? null : (
            <Chip
                className={classes.chip}
                variant="outlined"
                label={`${this.state.data.people} ${this.state.data.people === 1 ? "pessoa" : "pessoas"}`}
                avatar={<Avatar><AccountMultipleIcon /></Avatar>}
                color={"primary"}
            />
            )}
            {this.state.data.emitted === 2 || this.state.data.canceled === 1 ? null : (
            <Chip
                className={classes.chip}
                variant="outlined"
                label={this.state.data.emitted === 1 ? "Emitido" : "Não emitido"}
                avatar={<Avatar><QrcodeIcon /></Avatar>}
                color={this.state.data.emitted === 1 ? "primary" : "secondary"}
            />
            )}  
            <List>
                {this.state.data.cash_control_id <= 0 ? null : (
                <ListItem button component={RouterLink} to={`${routes.REPORT_DOCUMENTS}/cashcontrol/${this.state.data.cash_control_id}`}>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}><CashRegisterIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`O documento pertence ao caixa ${this.state.data.cash_control_id}`}
                        secondary="Clique para visualizar todos os documentos desse caixa"
                    />
                </ListItem>
                )}
                {!this.state.data.register_date ? null : (
                <ListItem>
                    <ListItemAvatar>
                    <Avatar className={classes.listAvatarSuccess}><PlusIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="O documento foi registrado"
                        secondary={formatDateTime(this.state.data.register_date)}
                    />
                </ListItem>
                )}
                {!this.state.data.closure_date ? null : (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}><CheckIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="O documento foi finalizado"
                        secondary={formatDateTime(this.state.data.closure_date)}
                    />
                </ListItem>
                )}
                {this.state.data.canceled === 0 ? null : (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarError}><DeleteForeverIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="O documento foi cancelado"
                        secondary={`${this.state.data.cancel_date ? formatDateTime(this.state.data.cancel_date) : "Data/hora não registrada"} - ${this.state.data.cancel_reason}`}
                    />
                </ListItem>
                )}
            </List>
            
            {this.state.data.products.length === 0 ? null : (
            <List
                className={classes.list}
                subheader={<ListSubheader disableSticky component="div" id="nested-list-subheader">Produtos</ListSubheader>}
            >    
                {this.state.data.products.map((product, index) => <Product
                    key={index}
                    {...product}
                />)}
            </List>
            )}
			{this.state.data.canceled ? null : (
            <List
                className={classes.list}
                subheader={<ListSubheader disableSticky component="div" id="nested-list-subheader">Total do pedido</ListSubheader>}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}><CartOutlineIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatMoney(this.state.data.total_value)}
                        secondary={"Total de produtos"}
                    />
                </ListItem>
                {this.state.data.total_addition < 0.01 ? null : (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}><PlusIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatMoney(this.state.data.total_addition)}
                        secondary={"Total de adicionais"}
                    />
                </ListItem>
                )}
                {this.state.data.total_discount < 0.01 ? null : (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarError}><MinusIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatMoney(this.state.data.total_discount)}
                        secondary={"Total de descontos"}
                    />
                </ListItem>
                )}
                {this.state.data.service_tax < 0.01 ? null : (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}><RoomServiceIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatMoney(this.state.data.service_tax)}
                        secondary={"Taxa de serviço"}
                    />
                </ListItem>
                )}
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.listAvatarSuccess}><SigmaIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={formatMoney(this.state.data.total_value + this.state.data.total_addition - this.state.data.total_discount + this.state.data.service_tax)}
                        secondary={"Total do pedido"}
                    />
                </ListItem>
            </List>
            )}

            {this.state.data.payments.length === 0 ? null : (
            <List
                className={classes.list}
                subheader={<ListSubheader disableSticky component="div" id="nested-list-subheader">Formas de pagamento</ListSubheader>}
            >
                {this.state.data.payments.map((payment, index) => <Payment
                    key={index}
                    icon={paymentTypes.find(el => el.id === payment.type).icon}
                    description={payment.method}
                    value={payment.value}
                />)}
            </List>
            )}
            
        </div>
        );
    }

}

DocumentInfo.contextType = context;

export default withStyles(styles, {withTheme: true})(DocumentInfo);
