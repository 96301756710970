import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Typography from '@material-ui/core/Typography';
import Loading from "../../utils/Loading";
import TableBody from '@material-ui/core/TableBody';
import { withRouter } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../utils/TableCell';
import Box from "../../utils/Box";
import {  formatMoney } from "../../../utils";
import Table from "../../utils/Table";
import { TableHead } from "@material-ui/core";
import EmptyState from "../../utils/EmptyState";
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';


const styles = theme => ({
})

class ServiceTax extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }
    
    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    load(control) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashcontrol/servicetax/"+control,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response,
        })))
        .catch(() => null))
    }

	render() {
        // const { classes } = this.props;

		return (
        <Box>
            {this.state.loading ? <Loading height={250} /> : (
            <Fragment>
                <Typography variant="h5" color="primary" gutterBottom>
                    Taxa de serviço
                </Typography>
                {this.state.data.length ? (
                <Table maxHeight={300}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Garçom</TableCell>
                            <TableCell align="right">Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell minWidth bold>{item.seller}</TableCell>
                            <TableCell align="right" minWidth>{formatMoney(item.service_tax)}</TableCell>
                        </TableRow>
                        ))}
                        <TableRow>
                            <TableCell minWidth bold>TOTAL</TableCell>
                            <TableCell align="right" minWidth bold>
                                {formatMoney(this.state.data.reduce((acc, e) => acc + e.service_tax, 0))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                ) : (
                <EmptyState icon={InformationOutlineIcon}>
                    Não foram pagas taxas de serviço neste caixa
                </EmptyState>
                )}
            </Fragment>
            )}
        </Box>
		);
    }
}

ServiceTax.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

ServiceTax.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(ServiceTax));
