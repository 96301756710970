import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import classnames from 'classnames';


const styles = theme => ({
    container: {
        overflowX: "auto",
        [theme.breakpoints.up("xs")]: {
            paddingLeft: theme.spacing.unit,
            paddingRight: theme.spacing.unit,
            paddingTop: 2*theme.spacing.unit,
            paddingBottom: 2*theme.spacing.unit,
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 2*theme.spacing.unit,
            paddingRight: 2*theme.spacing.unit,
            paddingTop: 3*theme.spacing.unit,
            paddingBottom: 3*theme.spacing.unit,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 3*theme.spacing.unit,
            paddingRight: 3*theme.spacing.unit,
            paddingTop: 3*theme.spacing.unit,
            paddingBottom: 3*theme.spacing.unit
        },
    }
})

class Box extends Component {
	render() {
		const { classes } = this.props;
		return (
        <Paper className={classnames({
            [classes.container]: true,
            [this.props.className]: this.props.className
        })}>{this.props.children}</Paper>
		);
	}
}

Box.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Box);
