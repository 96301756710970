import React, { Component } from "react";
import { withTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formatCNPJ } from "../../utils";
import StoreIcon from 'mdi-react/StoreIcon';

class Enterprises extends Component {
	render() {
		return (
        <Dialog open={this.props.open} onClose={this.props.close}>
            <DialogTitle>
                Selecione uma empresa
            </DialogTitle>
            <div>
                <List>
                {this.props.enterprises.map((enterprise, index) => (
                    <ListItem button key={index} onClick={() => this.props.selectEnterprise(enterprise.cnpj)}>
                        <ListItemIcon><StoreIcon /></ListItemIcon>
                        <ListItemText
                            primary={enterprise.name}
                            secondary={"CNPJ: " + formatCNPJ(enterprise.cnpj)}
                        />
                    </ListItem>
                ))}
                </List>
            </div>
        </Dialog>
		);
	}
}

export default withTheme()(Enterprises);
