import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Loading from "../utils/Loading";
import Box from "../utils/Box";
import CheckIcon from 'mdi-react/CheckIcon';
import EmptyState from "../utils/EmptyState";
import { formatDate, formatMoney } from "../../utils";
import Title from "../utils/Title";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from "@material-ui/core";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import PagedList from "../utils/PagedList";

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    }
})

class Billets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            billets: []
        };
    }

    componentDidMount() {
        this.getBillets();
    }

    getBillets() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/enterprise/billets",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            billets: response,
        })))
        .catch(() => null))
    }

	render() {
        const { classes } = this.props;

		return (
        <Grid container justify={"center"} spacing={24}>
            <Grid item xs={12}>
                <Box>
                    <Title>Boletos em aberto</Title>
                    <Typography style={{ marginBottom: "20px" }}>Aqui estão todos os boletos em aberto para seu estabelecimento. Após o pagamento ser efetuado, a baixa ocorre automaticamente em até 2 dias úteis.</Typography>
                    {this.state.loading ? <Loading height={300} /> : (
                        this.state.billets.length ? (
                        <PagedList>
                            {this.state.billets.map((item, key) => (
                            <React.Fragment key={key}>
                                <ListItem button component="a" href={item.link} target="_blank">
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <BarcodeIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${item.description}`}
                                        secondary={`${formatDate(item.maturity)} - ${formatMoney(item.value)}`}
                                    />
                                </ListItem>
                                {key === this.state.billets.length-1 || <Divider />}
                            </React.Fragment>
                            ))}
                        </PagedList>
                        ) : (
                        <EmptyState icon={CheckIcon}>
                            Você não tem boletos em aberto
                        </EmptyState>
                        )
                    )}
                    <Typography color="primary" variant="h4" gutterBottom  style={{ marginTop: "20px" }}>Quer desconto?</Typography>
                    <Typography gutterBottom>Você pode optar por efetuar o pagamento anual e ganhar 16% de desconto (2 mensalidade gratuitas). Basta entrar em contato com o suporte e nos informar que deseja a licença anual.</Typography>
                </Box>
            </Grid>
        </Grid>
		);
    }
}

Billets.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Billets.contextType = context;

export default withStyles(styles, {withTheme: true})(Billets);
