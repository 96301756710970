import React from "react";
import { List, TablePagination } from "@material-ui/core";

class PagedList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
			page: 0,
			rowsPerPage: 10
		};
    }

    range = (n) => {
        var x = [];
        for(let i=1; i<=n; i++) {
            x.push(i);
        }
        return x;
    }

	handleChangePage = (event, newPage) => this.setState(state => ({
		...state,
		page: newPage
	}))

	handleChangeRowsPerPage = event => this.setState(state => ({
		...state,
		page: 0,
		rowsPerPage: parseInt(event.target.value, 10)
	}))

    render = () => (
	<div>
		<List>
			{this.props.children
			.slice(
				this.state.page * this.state.rowsPerPage,
				this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
			)}
		</List>
		<TablePagination
			rowsPerPageOptions={[5, 10, 25]}
			component="div"
			count={this.props.children.length}
			rowsPerPage={this.state.rowsPerPage}
			page={this.state.page}
			labelRowsPerPage="Items por página"
			backIconButtonProps={{
				'aria-label': 'página anterior',
			}}
			nextIconButtonProps={{
				'aria-label': 'próxima página',
			}}
			labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
			onChangePage={this.handleChangePage}
			onChangeRowsPerPage={this.handleChangeRowsPerPage}
		/>
	</div>
	)
}

export default PagedList;