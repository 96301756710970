import React, { Component } from "react";
import {  withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import { withRouter, Link } from 'react-router-dom';
import Loading from "../utils/Loading";

import routes from "../../routes";
import WrenchIcon from 'mdi-react/WrenchIcon';
import Breadcrumbs from "../utils/Breadcrumbs";

const styles = theme => ({
    link: {
        color: theme.palette.primary.main
    },
    disabledLink: {
        color: "#666",
        textDecoration: "underline"
    },
    icon: {
        marginLeft: "5px"
    }
})

class Sections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }
    
    load() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/manual/sections",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    render() {
        const { classes } = this.props;

        return (
            this.state.loading ? <Loading height={250} /> : (
            <React.Fragment>
                <Breadcrumbs
                    items={[
                        {
                            label: "Temas",
                            active: true
                        }
                    ]}
                />
                <ul>
                    {this.state.data.map((el, index) => (
                    <li key={index}>
                        {el.name[0] === "#" ? (
                        <span className={classes.disabledLink}>
                            {el.name.substring(1)}<WrenchIcon size={12} className={classes.icon} />
                        </span>
                        ) : (
                        <Link to={routes.MANUAL + "/" + el.id} className={classes.link}>
                            {el.name}
                        </Link>
                        )}
                    </li>
                    ))}
                </ul>
            </React.Fragment>
           
            )
        );
    }
}

Sections.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Sections.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Sections));
