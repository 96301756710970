import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import { FormControl, TextField, Button, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import Box from "../../utils/Box";
import { withRouter, Switch, Route } from 'react-router-dom';
import Title from "../../utils/Title";
import routes from "../../../routes";
import EmptyState from "../../utils/EmptyState";
import FileSearchOutlineIcon from 'mdi-react/FileSearchOutlineIcon';
import DocumentInfo from "./DocumentInfo";
import Cashcontrol from "./Cashcontrol";


const styles = theme => ({
});

class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            method: props.match.params.type ? props.match.params.type : 'document',
            query: props.match.params.id ? props.match.params.id : ''
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }

    search() {
        if (this.state.query.trim() !== "") {
            this.context.pushHistory(`${routes.REPORT_DOCUMENTS}/${this.state.method}/${this.state.query}`);
        }
    }

    render() {
        return (
        <Grid container justify={"center"} spacing={24}>
            <Grid item xs={12}>
                <Box>
                    <Title>Pesquisar documento</Title>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label={"Digite sua pesquisa"}
                            name={"query"}
                            type={"number"}
                            value={this.state.query}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl component="fieldset" margin="dense" fullWidth>
                        <RadioGroup
                            aria-label="method"
                            name="method"
                            value={this.state.method}
                            onChange={this.onChange}
                            row
                        >
                            <FormControlLabel value="document" control={<Radio color="primary" />} label="Pesquisar pelo número do documento" />
                            <FormControlLabel value="cashcontrol" control={<Radio color="primary" />} label="Pesquisar por número do caixa" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <Button
                            onClick={() => this.search()}
                            variant="contained"
                            color="primary"
                        >
                            Pesquisar
                        </Button>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <Switch>
                        <Route exact path={`${routes.REPORT_DOCUMENTS}/cashcontrol/:id`} component={Cashcontrol} />
                        <Route exact path={`${routes.REPORT_DOCUMENTS}/document/:id`} component={DocumentInfo}/>
                        <Route render={() => <EmptyState icon={FileSearchOutlineIcon}>Utilize a pesquisa para encontrar documentos</EmptyState>} />
                    </Switch>
                </Box>
            </Grid>
        </Grid>
        );
    }
}

Documents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Documents.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Documents));
