import React, { Component } from "react";
import PropTypes from 'prop-types';
import context from "../../../context";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Subtitle from "../../utils/Subtitle";
import Loading from "../../utils/Loading";
import BarChart from '../../utils/BarChart';
import {TableHead, TableBody, TableRow} from '@material-ui/core';
import Table from '../../utils/Table';
import TableCell from '../../utils/TableCell';
import {formatMoney} from '../../../utils';

class Profit extends Component {

    constructor(props) {
        super(props);
        this.state = {
			data: null,
			loading: true
		};
    }


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.match.params.from !== this.props.match.params.from ||
            nextProps.match.params.to !== this.props.match.params.to ||
            nextProps.match.params.groupby !== this.props.match.params.groupby
        ) {
            this.load(
                nextProps.match.params.from,
                nextProps.match.params.to,
                nextProps.match.params.groupby
            );
        }
    }

    componentDidMount() {
        this.load(
            this.props.match.params.from,
            this.props.match.params.to,
            this.props.match.params.groupby
        );
    }

    load(from, to, groupby) {
        this.setState(state => ({
			...state,
			loading: true,
			data: null
        }), () => this.context.request({
            url: `/salessummary/profit/${from}/${to}/${groupby}`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
			loading: false,
			data: response
        })))
        .catch(error => null))
    }

    render() {
		return (
        <Box>
			<Subtitle>Lucro e custo nas vendas de produtos</Subtitle>
            {this.state.loading ? <Loading /> : (
			<div>	
				<BarChart
					xValues={this.state.data.map(el => el.date)}
					dataSet={[
						{
							legend: "Custo",
							values: this.state.data.map(el => el.cost),
							color: '#ef5350'
						},
						{
							legend: "Venda",
							values: this.state.data.map(el => el.value),
							color: '#4fc3f7'
						},
						{
							legend: "Lucro",
							values: this.state.data.map(el => el.value - el.cost),
							color: '#66bb6a'
						},
						{
							legend: "Vendas c/ custo registrado",
							values: this.state.data.map(el => el.cost_registered_value),
							color: '#795548'
						},
						{
							legend: "Lucro c/ custo registrado",
							values: this.state.data.map(el => el.cost_registered_value - el.cost),
							color: '#9c27b0'
						}
					]}
				/>	
				<Table height={250}>
					<TableHead>
						<TableRow>
							<TableCell>
								Data
							</TableCell>
							<TableCell>
								Custo
							</TableCell>
							<TableCell>
								Venda
							</TableCell>
							<TableCell>
								Lucro
							</TableCell>
							<TableCell>
								Vendas c/ custo<br />registrado
							</TableCell>
							<TableCell>
								Lucro c/ custo<br />registrado
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.data.map((data, index) => (
						<TableRow key={index}>
							<TableCell bold noWrap>
								{data.date}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(data.cost)}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(data.value)}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(data.value - data.cost)}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(data.cost_registered_value)}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(data.cost_registered_value - data.cost)}
							</TableCell>
						</TableRow>
						))}
						<TableRow>
							<TableCell bold minWidth noWrap>
								Total
							</TableCell>
							<TableCell bold noWrap>
								{formatMoney(this.state.data.reduce((acc, el) => acc + el.cost, 0))}
							</TableCell>
							<TableCell bold noWrap>
								{formatMoney(this.state.data.reduce((acc, el) => acc + el.value, 0))}
							</TableCell>
							<TableCell bold noWrap>
								{formatMoney(this.state.data.reduce((acc, el) => acc + el.value - el.cost, 0))}
							</TableCell>
							<TableCell bold noWrap>
								{formatMoney(this.state.data.reduce((acc, el) => acc + el.cost_registered_value, 0))}
							</TableCell>
							<TableCell bold noWrap>
								{formatMoney(this.state.data.reduce((acc, el) => acc + el.cost_registered_value - el.cost, 0))}
							</TableCell>

						</TableRow>
					</TableBody>
				</Table>
			</div>
            )}
        </Box>
        );
    }
}

Profit.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Profit.contextType = context;

export default withRouter(Profit);
