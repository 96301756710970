import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import Box from "../utils/Box";
import Title from "../utils/Title";
import { Switch, Route } from 'react-router-dom';
import routes from "../../routes";
import Subsections from "./Subsections";
import Content from "./Content";
import Sections from "./Sections";

const styles = theme => ({
    section: {
        borderBottom: "1px solid #ccc",
        color: "#aaa",
        fontSize: "20px",
        marginTop: "20px",
        paddingBottom: "10px"
    },
})

class Manual extends Component {

    render() {
		return (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <Box>
                    <Title>Manual Click</Title>
                    <Switch>
                        <Route
                            exact
                            path={routes.MANUAL}
                            render={() => <Sections />}
                        />
                        <Route
                            exact
                            path={routes.MANUAL + "/:sectionid"}
                            render={() => <Subsections />}
                        />
                        <Route
                            exact
                            path={routes.MANUAL + "/:sectionid/:subsectionid"}
                            render={() => <Content />}
                        />
                    </Switch>
                </Box>
            </Grid>
        </Grid>
		);
    }
}

Manual.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Manual.contextType = context;

export default withStyles(styles, {withTheme: true})(Manual);
