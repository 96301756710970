import CashIcon from 'mdi-react/CashIcon';
import CheckbookIcon from 'mdi-react/CheckbookIcon';
import CreditCardIcon from 'mdi-react/CreditCardIcon';
import CalendarPlusIcon from 'mdi-react/CalendarPlusIcon';
import StarOutlineIcon from 'mdi-react/StarOutlineIcon';
import AccountStarOutlineIcon from 'mdi-react/AccountStarOutlineIcon';
import BarcodeIcon from 'mdi-react/BarcodeIcon';
import BankIcon from 'mdi-react/BankIcon';
import SwapHorizontalBoldIcon from 'mdi-react/SwapHorizontalBoldIcon';
import QrCodeIcon from 'mdi-react/QrcodeIcon';
import BankTransferIcon from 'mdi-react/BankTransferIcon';
import TicketConfirmationIcon from 'mdi-react/TicketConfirmationIcon';
import CashRefundIcon from 'mdi-react/CashRefundIcon';
import AccountHeartIcon from 'mdi-react/AccountHeartIcon';
import CreditCardRefundIcon from 'mdi-react/CreditCardRefundIcon';

export const COLORS = [
    "#81c784",
    "#a1887f",
    "#e57373",
    "#ffb74d",
    "#d4e157",
    "#4dd0e1",
    "#9575cd",
    "#9fa8da",
    "#ce93d8",
    "#f06292"
];
export function calendarify(data, from, to, dateField) {
    const list = [];
    var cursor = new Date(from);
    var strCursor = from;
    while (strCursor < to) {
        strCursor = cursor.toISOString().substr(0, 10);
        list.push(data.length > 0 && data[0][dateField] === strCursor ? data.shift() : { [dateField]: strCursor })
        cursor.setDate(cursor.getDate() + 1);
    }
    return list;
}

export function formatDate(str) {
    if (!str || str === "") return "";
    return str.substring(8, 10)+"/"+str.substring(5, 7)+"/"+str.substring(0, 4);
}

export function formatDateTime(str) {
    if (!str || str === "") return "";
    return str.substring(8, 10) + "/" + str.substring(5, 7)+ "/" +str.substring(0, 4) + ", "+str.substring(11, 16);
}

export function formatCompactDateTime(str) {
    if (!str || str === "") return "";
    return str.substring(8, 10) + "/" + str.substring(5, 7) + " às "+str.substring(11, 13) + "h";
}

export function formatMoneyCompact(money) {
    if (money === undefined || money === null || money === 0) {
        return "R$ 0,00";
    }
    money = money.toFixed(2).split('.');
    money[0] = money[0].split(/(?=(?:...)*$)/).join('.');
    return money.join(',');
}

export function formatMoney(money) {
    if (money === undefined || money === null || money === 0) {
        return "R$ 0,00";
    }
    if (money < 0) {
        return `- R$ ${formatMoneyCompact(Math.abs(money))}`;
    }
    return `R$ ${formatMoneyCompact(money)}`;
}

export function formatCNPJ(cnpj) {
    if (cnpj === undefined || cnpj=== null || !cnpj.length) {
        return "";
    }
    if (cnpj.length === 11) {
        return `${cnpj.substr(0, 3)}.${cnpj.substr(3, 3)}.${cnpj.substr(6, 3)}-${cnpj.substr(9, 2)}`;                   
    } else {
        return `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(5, 3)}/${cnpj.substr(8, 4)}-${cnpj.substr(12, 2)}`;
    }
}

export function getTodaysDate(days=0) {
    var today = new Date();
    today.setDate(today.getDate() + days);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
}

export function formatAmount(amount) {
    if (!amount) {
        return "0";
    }
    const inteiro = parseInt(amount*1000);
    if (inteiro % 1000 === 0) {
        return parseInt(amount).toString();
    } else if (inteiro % 100 === 0) {
        return parseFloat(amount).toFixed(1).toString().replace(".", ',');
    } else if (inteiro % 10 === 0) {
        return parseFloat(amount).toFixed(2).toString().replace(".", ',');
    } else {
        return parseFloat(amount).toFixed(3).toString().replace(".", ',');
    }
}

export function sumArray(array, func) {
    return array.reduce((a, b) => a + parseFloat(func(b)),0);
}

export const paymentTypes = [
    {
        id: "DH",
        description: "DINHEIRO",
        icon: CashIcon
    },
    {
        id: "CH",
        description: "CHEQUE",
        icon: CheckbookIcon
    },
    {
        id: "CC",
        description: "CARTÃO DE CRÉDITO",
        icon: CreditCardIcon
    },
    {
        id: "CD",
        description: "CARTÃO DE DÉBITO",
        icon: CreditCardIcon
    },
    {
        id: "CR",
        description: "CONTAS A RECEBER",
        icon: CalendarPlusIcon
    },
    {
        id: "PE",
        description: "PERSONALIZADO",
        icon: StarOutlineIcon
    },
    {
        id: "CT",
        description: "CONTA CORRENTE",
        icon: AccountStarOutlineIcon
    },
    {
        id: "BO",
        description: "BOLETO",
        icon: BarcodeIcon
    },
    {
        id: "DP",
        description: "DEPÓSITO EM CONTA",
        icon: BankIcon
    },
    {
        id: "TR",
        description: "TROCA DE PRODUTOS",
        icon: SwapHorizontalBoldIcon
    },
    {
        id: "PX",
        description: "Pix",
        icon: QrCodeIcon,
    },
    {
        id: "PC",
        description: "Chave Pix",
        icon: QrCodeIcon,
    },
    {
        id: "TB",
        description: "Transferência Bancária",
        icon: BankTransferIcon,
    },
    {
        id: "VO",
        description: "Vouchers",
        icon: TicketConfirmationIcon,
    },
    {
        id: "FI",
        description: "Fidelidade",
        icon: AccountHeartIcon,
    },
    {
        id: "CB",
        description: "Cashback",
        icon: CashRefundIcon,
    },
    {
        id: "CV",
        description: "Crédito Virtual",
        icon: CreditCardRefundIcon,
    },
];
