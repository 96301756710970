import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import { TableHead, TableBody, TableRow, Typography } from "@material-ui/core";
import { formatMoney } from "../../../utils";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Loading from "../../utils/Loading";
import Table from "../../utils/Table";
import TableCell from "../../utils/TableCell";
import EmptyState from "../../utils/EmptyState";
import CheckIcon from "mdi-react/CheckIcon";
import ArrowDownBoldIcon from "mdi-react/ArrowDownBoldIcon";
import ArrowUpBoldIcon from "mdi-react/ArrowUpBoldIcon";


const styles = theme => ({
})

class Balance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
        this.load = this.load.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.from !== this.props.from || nextProps.to !== this.props.to) {
            this.load(nextProps.from, nextProps.to);
        }
    }

    componentDidMount() {
        this.load(this.props.from, this.props.to);
    }
    
    load(from, to) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashbookflow/balance/" + from + "/" + to,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    render() {
        return (
        <Box>
            <Typography variant="h5" color="primary" gutterBottom>Saldo</Typography>
            {this.state.loading ? <Loading height={250} /> : this.state.data.balance === 0 && this.state.data.exits === 0 && this.state.data.incomings === 0 ? (
                <EmptyState icon={CheckIcon}>Não houveram registros de movimentações neste período</EmptyState>
            ) : (
            <Fragment>
                <Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Entradas</TableCell>
                                <TableCell>Saídas</TableCell>
                                <TableCell>Saldo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{color: 'green'}}>
                                    <ArrowUpBoldIcon size={11} />
                                    {formatMoney(this.state.data.incomings)}
                                </TableCell>
                                <TableCell style={{color: 'red'}}>
                                    <ArrowDownBoldIcon size={11} />
                                    {formatMoney(-1*this.state.data.exits)}
                                </TableCell>
                                <TableCell bold>
                                    {this.state.data.balance >= 0 ?
                                        <span style={{color: 'green'}}>
                                            <ArrowUpBoldIcon size={11} />
                                            {formatMoney(this.state.data.balance)}
                                        </span> :
                                        <span style={{color: 'red'}}>
                                            <ArrowDownBoldIcon size={11} />
                                            {formatMoney(-1*this.state.data.balance)}
                                        </span>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Typography>
            </Fragment>
            )}
        </Box>
        );
    }
}

Balance.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Balance.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Balance));
