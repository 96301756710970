import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from '@material-ui/core/CardMedia';
import whiteLogo from '../../img/compact-white-logo.svg';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from "./Login";
import SendPasswordRecover from "./SendPasswordRecover";
import PropTypes from 'prop-types';
import PasswordRecover from "./PasswordRecover";
import NewUser from "./NewUser";
import routes from "../../routes";

const styles = theme => ({
		container: {
			height: "100vh",
		},
		cardMedia: {
			backgroundColor: theme.palette.primary.main,
			padding: "10px 0px"
		},
		gridItem: {
			maxWidth: "300px"
		},
});

class Session extends Component {
	render() {
		const { classes } = this.props;
		return (
		<Grid container alignItems="center" justify="center" className={classes.container}>
			<Grid item className={classes.gridItem}>
				<Card>
					<CardMedia
						component="img"
						className={classes.cardMedia}
						height="80"
						image={whiteLogo}
					/>
					<CardContent style={{padding: "0px"}}>
						<Switch>
							<Route exact path={routes.LOGIN} component={Login}/>
							<Route exact path={routes.SEND_PASSWORD_RECOVER} component={SendPasswordRecover}/>
							<Route exact path={routes.PASSWORD_RECOVER} component={PasswordRecover}/>
							<Route exact path={routes.NEW_USER} component={NewUser}/>
							<Route exact path={routes.LOGOUT} render={() => {
								localStorage.setItem("token", "");
								return (<Redirect to={routes.LOGIN} />);
							}} />
						</Switch>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
		);
	}
}

Session.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Session);
