import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import LoadingButton from "../utils/LoadingButton";
import routes from "../../routes";
import Box from "../utils/Box";
import Title from "../utils/Title";


const styles = theme => ({

})

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_password: '',
            new_password: '',
            new_password_confirm: '',
            loading: false
        };
        this.onChange = this.onChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    onChange(e) {
        e.persist();
        this.setState(state => ({
            ...state,
            [e.target.name]: e.target.value
		}));
    }

    changePassword() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/user/changepassword",
            method: "POST",
            body: {
                current_password: this.state.current_password,
                new_password: this.state.new_password,
                new_password_confirm: this.state.new_password_confirm,
            }
        })
        .then(() => this.setState(state => ({
            ...state,
            loading: false,
        }), () => this.context.pushHistory(routes.DASHBOARD)))
        .catch(() => this.setState(state => ({
            ...state,
            loading: false,
        }))))
    }

	render() {
		return (
        <Fragment>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Box>
                        <Title>Alterar sua senha</Title>
                        <form>
                            <FormControl margin="dense" fullWidth>
                                <TextField
                                    label="Senha atual"
                                    type="password"
                                    name="current_password"
                                    onChange={this.onChange}
                                />
                            </FormControl>
                            <FormControl margin="dense" fullWidth>
                                <TextField
                                    label="Nova senha"
                                    type="password"
                                    name="new_password"
                                    onChange={this.onChange}
                                />
                                <FormHelperText>
                                    Mínimo de 6 caracteres
                                </FormHelperText>
                            </FormControl>
                            <FormControl margin="dense" fullWidth>
                                <TextField
                                    label="Confirme a nova senha"
                                    type="password"
                                    name="new_password_confirm"
                                    onChange={this.onChange}
                                />
                            </FormControl>
                            <FormControl margin="dense" fullWidth>
                                <LoadingButton
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    loading={this.state.loading}
                                    onClick={this.changePassword}
                                >
                                    Alterar senha
                                </LoadingButton>
                            </FormControl>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
		);
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

ChangePassword.contextType = context;

export default withStyles(styles, {withTheme: true})(ChangePassword);
