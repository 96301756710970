import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import { withRouter } from 'react-router-dom';
import Loading from "../../utils/Loading";
import Breadcrumbs from "../../utils/Breadcrumbs";
import { ListItem, ListItemText, Divider, Avatar, Chip, Typography } from "@material-ui/core";
import { formatMoneyCompact, formatAmount } from "../../../utils";
import routes from "../../../routes";
import { Link as RouterLink } from 'react-router-dom';
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import CurrencyUsdOffIcon from "mdi-react/CurrencyUsdOffIcon";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import WarehouseIcon from "mdi-react/WarehouseIcon";
import PagedList from "../../utils/PagedList";

const styles = theme => ({
    icon: {
        color: theme.palette.grey.A700
    },
    primary: {
        color: theme.palette.primary.main 
    },
    chip: {
        marginRight: "5px",
        marginBottom: "5px"
    }
})

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        this.load();
    }
    
    load() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: `/productlist/groups/${this.props.match.params.groupid}/subgroups/${this.props.match.params.subgroupid}/products`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    render() {
        const { classes } = this.props;
        return (
        <div>
            <Breadcrumbs
                items={[
                    {
                        label: "Grupos",
                        link: routes.REPORT_PRODUCT_LIST
                    },
                    {
                        label: `Grupo "${this.state.data.group_description}"`,
                        loading: this.state.loading,
                        link: `${routes.REPORT_PRODUCT_LIST}/${this.state.data.group_id}/subgroups`                    },
                    {
                        label: `Subgrupo "${this.state.data.subgroup_description}"`,
                        active: true,
                        loading: this.state.loading
                    }
                ]} 
            />
            {this.state.loading ? <Loading height={250} /> : (
            <PagedList>
                {this.state.data.list.map((prod, index) => (
                <React.Fragment>
                    <ListItem
                        button
                        className={classes.listItem}
                        component={RouterLink}
                        to={routes.REPORT_PRODUCT(prod.barcode)}
                        key={index}
                    >
                        <ListItemText
                            primary={(
                            <Typography noWrap>
                                <Chip
                                    className={classes.chip} 
                                    color="primary"
                                    label={prod.barcode}
                                    avatar={<Avatar><BarcodeIcon /></Avatar>}
                                />
                                {prod.name}
                            </Typography>
                            )}
                            secondary={(
                            <Typography noWrap>
                                <Chip
                                    className={classes.chip} 
                                    color="primary"
                                    variant="outlined"
                                    label={formatMoneyCompact(prod.value)}
                                    avatar={<Avatar><CurrencyUsdIcon /></Avatar>}
                                />
                                <Chip
                                    className={classes.chip} 
                                    color="primary"
                                    variant="outlined"
                                    label={formatMoneyCompact(prod.cost)}
                                    avatar={<Avatar><CurrencyUsdOffIcon /></Avatar>}
                                />
                                <Chip
                                    className={classes.chip} 
                                    variant="outlined"
                                    color={prod.control_storage ? "primary" : "secondary"}
                                    label={`Est.: ${prod.control_storage ? formatAmount(prod.storage) : "-"}`}
                                    avatar={<Avatar><WarehouseIcon /></Avatar>}
                                />
                            </Typography>
                            )}
                        />
                    </ListItem>
                    <Divider component="li" />
                </React.Fragment>
                ))}
            </PagedList>
            )}
        </div>
        );
    }
}

Products.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Products.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Products));
