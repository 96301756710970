import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import { TableHead, TableBody, TableRow, Typography } from "@material-ui/core";
import { formatMoney, formatDate } from "../../../utils";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Loading from "../../utils/Loading";
import Table from "../../utils/Table";
import TableCellButton from "../../utils/TableCellButton";
import TableCell from "../../utils/TableCell";
import EmptyState from "../../utils/EmptyState";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ArrowUpBoldIcon from "mdi-react/ArrowUpBoldIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import CheckIcon from "mdi-react/CheckIcon";
import PieChart from "../../utils/PieChart";


const styles = theme => ({
})

class Incomings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
        this.load = this.load.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.from !== this.props.from || nextProps.to !== this.props.to) {
            this.load(nextProps.from, nextProps.to);
        }
    }

    componentDidMount() {
        this.load(this.props.from, this.props.to);
    }
    
    load(from, to) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashbookflow/incomings/" + from + "/" + to,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response.map(plan => ({
                ...plan,
                visible: false,
                subplans: plan.subplans.map(subplan => ({
                    ...subplan,
                    visible: false,
                }))
            }))
        })))
        .catch(() => null))
    }


    switchPlan(index) {
        this.setState(state => {
            state.data[index].visible = !state.data[index].visible;
            return state;
        });
    }

    switchSubplan(plan_index, subplan_index) {
        this.setState(state => {
            state.data[plan_index].subplans[subplan_index].visible = !state.data[plan_index].subplans[subplan_index].visible;
            return state;
        });
    }


    render() {
        const total = this.state.data.reduce((acc, el) => acc + el.value, 0);
        return (
        <Box>
            <Typography variant="h5" color="primary" gutterBottom>Entradas</Typography>
            {this.state.loading ? <Loading height={250} /> : this.state.data.length === 0 ? (
                <EmptyState icon={CheckIcon}>Não houveram registros de entradas neste período</EmptyState>
            ) : (
            <Fragment>
                <PieChart
                    data={this.state.data}
                    dataKey="value"
                    nameKey="description"
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell minWidth></TableCell>
                            <TableCell colSpan={2}>Descrição</TableCell>
                            <TableCell align="right">Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((plan, plan_index) => (
                        <Fragment key={plan_index}>
                            <TableRow>
                                <TableCellButton
                                    tooltip={plan.visible ? "Esconder detalhes do plano" : "Mostra detalhes do plano"}
                                    icon={plan.visible ? ChevronUpIcon : ChevronDownIcon}
                                    onClick={() => this.switchPlan(plan_index)}
                                />
                                <TableCell colSpan={2} bold>{plan.description}</TableCell>
                                <TableCell bold align="right">
                                    <span style={{color: "green"}}>
                                        <ArrowUpBoldIcon size={11} /> {formatMoney(plan.value)}
                                    </span>
                                </TableCell>
                            </TableRow>
                            {plan.visible && plan.subplans.map((subplan, subplan_index) => (
                            <Fragment key={subplan_index}>
                                <TableRow>
                                    <TableCell minWidth noPadding></TableCell>
                                    <TableCellButton
                                        onClick={() => this.switchSubplan(plan_index, subplan_index)}
                                        tooltip={subplan.visible ? "Esconder detalhes do subplano" : "Mostra detalhes do subplano"}
                                        icon={subplan.visible ? ChevronUpIcon : ChevronDownIcon}
                                    />
                                    <TableCell bold>{subplan.description}</TableCell>
                                    <TableCell bold align="right">
                                        <span style={{color: "green"}}>
                                        <ArrowUpBoldIcon size={11} /> {formatMoney(subplan.value)}
                                        </span>
                                    </TableCell>
                                </TableRow>
                                {subplan.visible && subplan.data.map((data, dindex) => (
                                <TableRow key={dindex} >
                                    <TableCell minWidth noPadding></TableCell>
                                    <TableCell minWidth noPadding></TableCell>
                                    <TableCell>{formatDate(data.date)} - {data.description}</TableCell>
                                    <TableCell align="right">
                                        <span style={{color:"green"}}>
                                            <ArrowUpBoldIcon size={11} /> {formatMoney(data.value)}
                                        </span>
                                    </TableCell>
                                </TableRow>
                                ))}
                            </Fragment>
                            ))}
                        </Fragment>
                        ))}
                        <TableRow>
                            <TableCell minWidth noPadding></TableCell>
                            <TableCell minWidth noPadding></TableCell>
                            <TableCell bold>TOTAL</TableCell>
                            <TableCell bold align="right">
                                <span style={{color: "green" }}>
                                    <ArrowUpBoldIcon size={11} /> {formatMoney(total)}
                                </span>
                            </TableCell>                                    
                        </TableRow>
                    </TableBody>
                </Table>
            </Fragment>
            )}
        </Box>
        );
    }
}

Incomings.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Incomings.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Incomings));
