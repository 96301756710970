import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({
    divider: {
        marginBottom: "20px"
    }
})

class Title extends Component {
	render() {
		const { classes } = this.props;
		return (
        <Fragment>
            <Typography color="primary" variant="h4" gutterBottom>{this.props.children}</Typography>
            <Divider className={classes.divider} />
        </Fragment>
		);
	}
}

Title.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Title);
