import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Typography from '@material-ui/core/Typography';
import Loading from "../../utils/Loading";
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../utils/TableCell';
import Box from "../../utils/Box";
import {  formatMoney, formatDateTime, formatAmount } from "../../../utils";
import Table from "../../utils/Table";
import { TableHead, Link } from "@material-ui/core";
import EmptyState from "../../utils/EmptyState";
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import routes from "../../../routes";
import { Link as RouterLink } from 'react-router-dom';


const styles = theme => ({
})

class Depletions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    load(control) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashcontrol/canceleddocuments/"+control,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response,
        })))
        .catch(() => null))
    }

    

	render() {
        // const { classes } = this.props;

		return (
        <Box>
            {this.state.loading ? <Loading height={250} /> : (
            <Fragment>
                <Typography variant="h5" color="primary" gutterBottom>
                    Documentos cancelados
                </Typography>
                {this.state.data.length ? (
                <Table maxHeight={300}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Documento</TableCell>
                            <TableCell>Produtos</TableCell>
                            <TableCell align="right">Valor</TableCell>
                            <TableCell>Motivo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell minWidth>{formatDateTime(item.cancel_date)}</TableCell>
                            <TableCell minWidth bold>
                                <Link component={RouterLink} to={`${routes.REPORT_DOCUMENTS}/document/${item.document_id}`}>
                                    {item.document_id}
                                </Link>
                            </TableCell>
                            <TableCell minWidth>{formatAmount(item.items)}</TableCell>
                            <TableCell align="right" minWidth>{formatMoney(item.value)}</TableCell>
                            <TableCell>{item.reason}</TableCell>
                        </TableRow>
                        ))}
                        <TableRow>
                            <TableCell minWidth bold>TOTAL</TableCell>
                            <TableCell minWidth></TableCell>
                            <TableCell minWidth bold>
                                {formatAmount(this.state.data.reduce((acc, e) => acc + e.items, 0))}
                            </TableCell>
                            <TableCell align="right" minWidth bold>
                                {formatMoney(this.state.data.reduce((acc, e) => acc + e.value, 0))}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                ) : (
                <EmptyState icon={InformationOutlineIcon}>
                    Não foram realizados cancelamentos de documentos nesse caixa
                </EmptyState>
                )}
            </Fragment>
            )}
        </Box>
		);
    }
}

Depletions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Depletions.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Depletions));
