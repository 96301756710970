import React from "react";
import context from "../../../context";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Loading from "../../utils/Loading";
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemIcon, Divider } from "@material-ui/core";
import EmptyState from "../../utils/EmptyState";
import { Link as RouterLink, withRouter } from "react-router-dom";
import routes from "../../../routes";
import { formatDateTime } from "../../../utils";
import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import PagedList from "../../utils/PagedList";

const styles = theme => ({
    container: {
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "230px",
        border: "1px solid rgb(224, 224, 224)",
    },
});

class Controls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.from !== nextProps.match.params.from || this.props.match.params.to !== nextProps.match.params.to) {
            this.load(nextProps.match.params.from, nextProps.match.params.to);
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.from, this.props.match.params.to);
    }

    load(from, to) {
        if (from && to) {
            this.setState(state => ({
                ...state,
                loading: true,
            }), () => this.context.request({
                url: `/cashcontrol/list/${from}/${to}`,
                method: "GET"
            })
            .then(response => this.setState(state => ({
                ...state,
                loading: false,
                data: response,
            })))
            .catch(() => null))
        }
    }

	render() {
        const { classes } = this.props;
		return this.props.match.params.from && this.props.match.params.to ? (
        <React.Fragment>
            <Typography color="primary" variant="h6" gutterBottom>Selecione um caixa</Typography>
            {this.state.loading ? <Loading height={220} /> : this.state.data.length ? (
            <div className={classes.container}>
                <PagedList>
                    {this.state.data.map((control, index) => (
                    <React.Fragment>
                        <ListItem
                            button
                            component={RouterLink}
                            to={`${routes.REPORT_CASH_CONTROL}/${this.props.match.params.from}/${this.props.match.params.to}/${control.local_id}`}
                        >
                            <ListItemIcon>
                                <CashRegisterIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`Caixa ${control.local_id}`}
                                secondary={`Aberto ${formatDateTime(control.opening_date)} e fechado ${formatDateTime(control.closure_date)}`}
                            />
                        </ListItem>
                        <Divider component="li" />
                    </React.Fragment>
                    ))}
                </PagedList>
            </div>
            ) : (
            <EmptyState icon={CashRegisterIcon}>
                Não existem caixas aberto neste intervalo de data
            </EmptyState>
            )}
        </React.Fragment>
		) : (
            <EmptyState icon={CashRegisterIcon}>Defina um intervalo de data</EmptyState>
        );
    }
}

Controls.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Controls.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Controls));
