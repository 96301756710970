import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Typography from '@material-ui/core/Typography';
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import { Button } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import routes from "../../../routes";

const styles = theme => ({
    centerAlign: {
        textAlign: "center"
    }
})

class Documents extends Component {
	render() {
        const { classes } = this.props;
		return (
        <Box>
            <Typography variant="h5" color="primary" gutterBottom>
                Documentos
            </Typography>
            <div className={classes.centerAlign}>
                <Button variant="contained" color="primary" component={RouterLink} to={`${routes.REPORT_DOCUMENTS}/cashcontrol/${this.props.match.params.id}`}>
                    Visualizar todos os documentos do caixa
                </Button>
            </div>
        </Box>
		);
    }
}

Documents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Documents.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Documents));
