import React, { Component } from "react";
import context from "../../../context";
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from "@material-ui/core";
import { formatAmount, formatMoney, formatDateTime } from "../../../utils";
import PlaylistRemoveIcon from "mdi-react/PlaylistRemoveIcon";
import PlaylistPlusIcon from "mdi-react/PlaylistPlusIcon";
import {Link as RouterLink} from 'react-router-dom';
import routes from "../../../routes";

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "11px"
    },
    avatarCanceled: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: "11px"
    }
});


class Product extends Component {

    render() {
        const { classes } = this.props;
        return (
        <ListItem button component={RouterLink} to={routes.REPORT_PRODUCT(this.props.barcode)}>
            <ListItemAvatar>
                <Avatar className={this.props.canceled ? classes.avatarCanceled : classes.avatar}>
                    {this.props.canceled ? <PlaylistRemoveIcon /> : <PlaylistPlusIcon />}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={`${this.props.barcode} - ${this.props.description}`}
                secondary={this.props.canceled ? (
                    `Quant.:${formatAmount(this.props.amount)}; ${this.props.cancel_date ? formatDateTime(this.props.cancel_date) : "Data não informada"} - ${this.props.cancel_reason ? this.props.cancel_reason : "Motivo não informado"}`
                ) : (
                    `Quant.:${formatAmount(this.props.amount)}; Valor: ${formatMoney(this.props.value)};${this.props.addition >= 0.01 ? ` Adicional/Taxa de serviço: ${formatMoney(this.props.addition)}` : ""}${this.props.discount >= 0.01 ? ` Desconto: ${formatMoney(this.props.discount)};` : ""}`
                )}
            />
        </ListItem>
        );
    }

}

Product.contextType = context;

export default withStyles(styles, {withTheme: true})(Product);