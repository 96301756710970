import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Title from "../../utils/Title";

import { FormControl, TextField, Button  } from "@material-ui/core";
import { getTodaysDate } from "../../../utils";
import queryString from 'query-string';
import Exits from "./Exits";
import Incomings from "./Incomings";
import routes from "../../../routes";
import Balance from "./Balance";

const styles = theme => ({
})

class CashbookFlow extends Component {


    constructor(props) {
        super(props);
        const query = queryString.parse(props.location.search);
        this.state = {
            loading: true,
            from: query.from ? query.from : getTodaysDate(-7),
            to: query.to ? query.to : getTodaysDate(),
            selected_from: query.from ? query.from : getTodaysDate(-7),
            selected_to: query.to ? query.to : getTodaysDate(),
        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(e) {
        e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }

    search() {
        if (this.state.from && this.state.to) {
            this.setState(state => ({
                ...state,
                selected_from: state.from,
                selected_to: state.to
            }), () => this.context.pushHistory(routes.REPORT_CASHBOOK_FLOW + "?" + queryString.stringify({
                from: this.state.from,
                to: this.state.to
            })));
        } else {
            this.context.pushMessage("Insira um intervalo de data válido");
        }
    }

    render() {
        return (
        <Grid container justify={"center"} spacing={24}>
            <Grid item xs={12} xl={10}>
                <Box>
                    <Title>Fluxo de caixa</Title>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label={"De"}
                            name={"from"}
                            type={"date"}
                            value={this.state.from}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <TextField
                            label={"Até"}
                            name={"to"}
                            type={"date"}
                            value={this.state.to}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="dense" fullWidth>
                        <Button
                            onClick={() => this.search()}
                            variant="contained"
                            color="primary"
                        >
                            Buscar
                        </Button>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} xl={10}>
                <Balance
                    from={this.state.selected_from}
                    to={this.state.selected_to}
                />
            </Grid>
            <Grid item xs={12} xl={10}>
                <Incomings
                    from={this.state.selected_from}
                    to={this.state.selected_to}
                />
            </Grid>
            <Grid item xs={12} xl={10}>
                <Exits
                    from={this.state.selected_from}
                    to={this.state.selected_to}
                />
            </Grid>
        </Grid>
        );
    }
}

CashbookFlow.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

CashbookFlow.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(CashbookFlow));
