import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import Loading from "./Loading";
import LoadingButton from "./LoadingButton";
import EmptyState from "./EmptyState";
import { FormControl, TextField, Select, OutlinedInput } from "@material-ui/core";
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { getTodaysDate } from "../../utils";


const styles = theme => ({
    select: {
        paddingTop: "4px",
        paddingBottom: "4px"
    },
    button: {
        marginTop: "18px"
    }
})

class TimeSeries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            from: getTodaysDate(-7),
            to: getTodaysDate(),
            group: 'DAY',
            selected_from: getTodaysDate(-7),
            selected_to: getTodaysDate(),
            selected_group: 'DAY',
            loading: true,
            error: false
        };
        this.onChange = this.onChange.bind(this);
        this.search = this.search.bind(this);
    }

    onChange(e) {
        e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(state => ({
            ...state,
            loading: true,
            error: false,
            selected_from: state.from,
            selected_to: state.to,
            selected_group: state.group
        }), () => this.context.request({
            url: this.props.url(
                this.state.selected_from,
                this.state.selected_to,
                this.state.selected_group
            ),
            method: this.props.method || "GET"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            error: false,
            data: response
        })))
        .catch(() => this.setState(state => ({
            ...state,
            loading: false,
            error: true
        }))))
    }

    render() {
        const { classes } = this.props;
        return (
        <Grid container justify="center" spacing={8}>
            <Grid item xs={12}>
                {this.state.loading ?
                <Loading height={300} /> :
                this.state.error ?
                <EmptyState icon={AlertCircleOutlineIcon}>Impossível visualizar os dados</EmptyState>
                : this.props.children(this.state.data)}
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <TextField
                        label="De"
                        name="from"
                        type="date"
                        value={this.state.from}
                        onChange={this.onChange}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <TextField
                        label="Até"
                        name="to"
                        type="date"
                        value={this.state.to}
                        onChange={this.onChange}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <Select
                        className={classes.select}
                        native
                        value={this.state.group}
                        onChange={this.onChange}
                        input={<OutlinedInput name="group" labelWidth={0} />}
                    >
                        <option value="DAY">Agrupar por dia</option>
                        <option value="MONTH">Agrupar por mês</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <LoadingButton
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    loading={this.state.loading}
                    onClick={this.search}
                >
                    Buscar
                </LoadingButton>
            </Grid>
        </Grid>
        );
    }
}

TimeSeries.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

TimeSeries.contextType = context;

export default withStyles(styles, {withTheme: true})(TimeSeries);
