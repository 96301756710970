import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import context from "../../../context";
import Typography from '@material-ui/core/Typography';
import Loading from "../../utils/Loading";
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '../../utils/TableCell';
import Box from "../../utils/Box";
import {  formatMoney } from "../../../utils";
import Table from "../../utils/Table";
import EmptyState from "../../utils/EmptyState";
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import SubdirectoryArrowRightIcon from 'mdi-react/SubdirectoryArrowRightIcon';


const styles = theme => ({
    icon: {
        color: theme.palette.grey.A700
    },
    primary: {
        color: theme.palette.primary.main 
    }
})

class Totalizers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    load(control) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/cashcontrol/totalizers/"+control,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

	render() {
        const { classes } = this.props;

		return (
        <Box>
            {this.state.loading ? <Loading height={250} /> : (
            <Fragment>
                <Typography variant="h5" color="primary" gutterBottom>
                    Totalizadores
                </Typography>
                {this.state.data.length ? (
                <Table maxHeight={300}>
                    <TableBody>
                        {this.state.data.map((method, key) => (
                        <Fragment key={key}>
                            <TableRow>
                                <TableCell className={classes.primary} colSpan={2} variant="head" bold>
                                    TOTAL {method.name}
                                </TableCell>
                                <TableCell className={classes.primary} variant="head" align="right" bold minWidth>
                                    {formatMoney(method.total)}
                                </TableCell>
                            </TableRow>
                            {method.data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell minWidth><SubdirectoryArrowRightIcon className={classes.icon} /></TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell minWidth align="right">{formatMoney(item.value)}</TableCell>
                            </TableRow>
                            ))}
                        </Fragment>
                        ))}
                    </TableBody>
                </Table>
                ) : (
                <EmptyState icon={InformationOutlineIcon}>
                    Não foram realizadas vendas neste caixa
                </EmptyState>
                )}
            </Fragment>
            )}
        </Box>
		);
    }
}

Totalizers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Totalizers.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Totalizers));
