import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import StoreIcon from 'mdi-react/StoreIcon';
import { formatCNPJ } from "../../utils";
import Loading from "../utils/Loading";
import { ListItemAvatar, Avatar, CircularProgress } from "@material-ui/core";

const styles = theme => ({
    noPadding: {
        padding: "0px"
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 0
    },
    noWrap: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "180px"
    }
});

class Profile extends Component {
	render() {
        const { classes } = this.props;
		return this.props.loading ? <Loading height={61} /> : (
        <List className={classes.noPadding}>
            <ListItem
                button
                onClick={this.props.loadEnterprises}
            >
                <ListItemAvatar className={classes.avatar}>
                    <Avatar>
                    {this.props.loadingEnterprises ? (
                        <CircularProgress
                            disableShrink
                            size={24}
                            color="inherit"
                        />
                    ): (
                        <StoreIcon />
                    )}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primaryTypographyProps={{
                        className: classes.noWrap
                    }}
                    primary={this.props.name}
                    secondary={formatCNPJ(this.props.cnpj)}
                />
            </ListItem>
        </List>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Profile.contextType = context;

export default withStyles(styles, {withTheme: true})(Profile);
