import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Title from "../../utils/Title";
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from "@material-ui/core";
import Loading from "../../utils/Loading";
import { formatMoney, formatAmount } from "../../../utils";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import WarehouseIcon from "mdi-react/WarehouseIcon";
import CurrencyUsdOffIcon from "mdi-react/CurrencyUsdOffIcon";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import SourceRepositoryIcon from 'mdi-react/SourceRepositoryIcon';
import SourceRepositoryMultipleIcon from 'mdi-react/SourceRepositoryMultipleIcon';
import {Link as RouterLink} from 'react-router-dom';
import routes from "../../../routes";

const styles = theme => ({
    avatarPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    avatarSecondary: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
})

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true
        };
    }

    componentDidMount() {
        this.setState(state => ({
            ...state,
            loading: true
        }), () => this.context.request({
            url: "/product/header/" + this.props.barcode,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    render() {
        const { classes } = this.props;

        return (
        <Grid item xs={12} lg={6}>
            <Box>
            {this.state.loading ? <Loading height={300} /> : (
            <div>
                <Title>{this.state.data.name}</Title>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarPrimary}>
                                <BarcodeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Código de barras"
                            secondary={this.props.barcode}
                        />
                    </ListItem>
                    <ListItem button component={RouterLink} to={`${routes.REPORT_PRODUCT_LIST}/${this.state.data.section_id}/subgroups`}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarPrimary}>
                                <SourceRepositoryIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Grupo"
                            secondary={this.state.data.section}
                        />
                    </ListItem>
                    <ListItem button component={RouterLink} to={`${routes.REPORT_PRODUCT_LIST}/${this.state.data.section_id}/subgroups/${this.state.data.subsection_id}/products`}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarPrimary}>
                                <SourceRepositoryMultipleIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Subgrupo"
                            secondary={this.state.data.subsection}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarPrimary}>
                                <CurrencyUsdIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Preço de venda"
                            secondary={formatMoney(this.state.data.value)}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.avatarPrimary}>
                                <CurrencyUsdOffIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Preço de custo"
                            secondary={formatMoney(this.state.data.cost)}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={this.state.data.control_storage === 0 ? classes.avatarSecondary : classes.avatarPrimary}>
                                <WarehouseIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Estoque ativado"
                            secondary={this.state.data.control_storage === 0 ? "NÂO" : "SIM"}
                        />
                    </ListItem>
                    {this.state.data.control_storage !== 0 && (
                    <React.Fragment>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarPrimary}>
                                    <WarehouseIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Estoque mínimo"
                                secondary={formatAmount(this.state.data.minimum_storage)}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarPrimary}>
                                    <WarehouseIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Estoque atual (loja)"
                                secondary={formatAmount(this.state.data.store_storage)}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatarPrimary}>
                                    <WarehouseIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Estoque atual (depósito)"
                                secondary={formatAmount(this.state.data.warehouse_storage)}
                            />
                        </ListItem>
                    </React.Fragment>
                    )}
                </List>
            </div>  
            )}
            </Box>
        </Grid>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Header.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Header));
