import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { ListItem, Divider, ListItemText, Avatar, Typography, Chip } from "@material-ui/core";
import Box from "../../utils/Box";
import Title from "../../utils/Title";
import context from "../../../context";
import PagedList from "../../utils/PagedList";
import Loading from "../../utils/Loading";
import routes from "../../../routes";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import AttachMoneyIcon from "mdi-react/AttachMoneyIcon";
import { formatAmount } from "../../../utils";
import EmptyState from "../../utils/EmptyState";
import CheckIcon from "mdi-react/CheckIcon";

const styles = theme => ({
    chip: {
        marginRight: "3px",
        marginTop: "3px"
    }
});

class YearAverage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        this.load()
    }

    load() {
        this.setState(state => ({
            ...state,
            loading: true,
            data: []
        }), () => this.context.request({
            method: "GET",
            url: "/shoppinglist/yearaverage"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        }))).catch(err => null))
    }

    render() {
        const { classes } = this.props;
        return (
        <Box>
            <Title>Baseado em vendas nos últimos anos</Title>
            {this.state.loading ? <Loading height={250} /> : this.state.data.length === 0 ? (
            <EmptyState icon={CheckIcon}>
                Não existem itens acabando nos próximos dias
            </EmptyState>
            ) : (
            <PagedList>
                {this.state.data.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem button component={RouterLink} to={routes.REPORT_PRODUCT(item.barcode)}>
                        <ListItemText
                            primaryTypographyProps={{
                                className: classes.primary
                            }}
                            secondaryTypographyProps={{
                                className: classes.secondary
                            }}
                            primary={(
                            <Typography noWrap>
                                <Chip
                                    className={classes.chip}
                                    color="primary"
                                    variant="default"
                                    avatar={<Avatar><BarcodeIcon /></Avatar>}
                                    label={item.barcode}
                                />
                                {item.name}
                            </Typography>
                            )}
                            secondary={(
                            <Typography noWrap>
                                <Chip
                                    className={classes.chip}
                                    variant="outlined"
                                    color="primary"
                                    avatar={<Avatar><AttachMoneyIcon /></Avatar>}
                                    label={`Venda por dia: ${formatAmount(item.day_avg)}`}
                                />
                                <Chip
                                    className={classes.chip}
                                    variant="outlined"
                                    color="secondary"
                                    avatar={<Avatar><AttachMoneyIcon /></Avatar>}
                                    label={`Est. em 7 dias: ${formatAmount(item.warehouse_storage + item.store_storage - 7*item.day_avg)}${
                                        item.minimum_storage > 0 ? ` (min: ${formatAmount(item.minimum_storage)})` : ""
                                    }`}
                                />
                            </Typography>
                            )}
                        />
                    </ListItem>
                    <Divider component="li" />
                </React.Fragment>
                ))}
            </PagedList>
            )}
        </Box>
        );
    }
}

YearAverage.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

YearAverage.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(YearAverage));
