import React, { Component } from "react";

import { Switch, Route, Redirect } from 'react-router-dom'
import ChangePassword from "../changepassword/ChangePassword";
import routes from "../../routes";
import UserAccess from "../useracces/UserAccess";
import Billets from "../billets/Billets";
import ServiceOrders from "../serviceorders/ServiceOrders";
import Dashboard from "../dashboard/Dashboard";
import CashControl from "../reports/cashcontrol/CashControl";
import Reports from "../reports_menu/Reports";
import ShoppingList from '../reports/shoppinglist/ShoppingList';
import ProductInfo from "../reports/productinfo/ProductInfo";
import BillsToPay from "../reports/billstopay/BillsToPay";
import BillsToReceive from "../reports/billstoreceive/BillsToReceive";
import CashbookFlow from "../reports/cashbookflow/CashbookFlow";
import Manual from "../manual/Manual";
import ProductList from "../reports/productlist/ProductList";
import SalesSummary from "../reports/salessummary/SalesSummary.js";
import Documents from "../reports/documents/Documents";

class Routes extends Component {
	render() {
		return (
        <Switch>
            <Route exact path={routes.NAV} render={() => <Redirect to={routes.DASHBOARD} />} />
            <Route exact path={routes.CHANGE_PASSWORD} component={ChangePassword} />
            <Route exact path={routes.USER_ACCESS} component={UserAccess} />
            <Route exact path={routes.BILLETS} component={Billets} />
            <Route exact path={routes.SERVICE_ORDERS} component={ServiceOrders} />
            <Route exact path={routes.DASHBOARD} component={Dashboard} />
            <Route exact path={routes.REPORTS} component={Reports} />
            <Route
                exact
                path={[
                    `${routes.REPORT_CASH_CONTROL}/:from/:to/:id`,
                    `${routes.REPORT_CASH_CONTROL}/:from/:to`,
                    `${routes.REPORT_CASH_CONTROL}/:id`,                
                    routes.REPORT_CASH_CONTROL
                ]}
                component={CashControl}
            />
            <Route path={routes.REPORT_SHOPPING_LIST} component={ShoppingList} />
            <Route exact path={routes.REPORT_PRODUCT()} component={ProductInfo} />
            <Route path={routes.REPORT_BILLS_TO_PAY} component={BillsToPay} />
            <Route path={routes.REPORT_BILLS_TO_RECEIVE} component={BillsToReceive} />
            <Route path={routes.REPORT_CASHBOOK_FLOW} component={CashbookFlow} />
            <Route path={routes.REPORT_PRODUCT_LIST} component={ProductList} />
            <Route
                path={[
                    `${routes.REPORT_SALES_SUMMARY}/:from/:to/:groupby`,
                    routes.REPORT_SALES_SUMMARY
                ]}
                component={SalesSummary}
            />
            <Route
                path={[
                    `${routes.REPORT_DOCUMENTS}/:type/:id`,
                    routes.REPORT_DOCUMENTS
                ]}
                component={Documents}
            />
            <Route path={routes.MANUAL} component={Manual} />
        </Switch>
		);
    }
}

export default Routes;
