import React, { Component } from "react";
import {  withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import { withRouter } from 'react-router-dom';
import Loading from "../utils/Loading";
import { Typography } from "@material-ui/core";
import Breadcrumbs from "../utils/Breadcrumbs";
import routes from "../../routes";

const styles = theme => ({
    div: {
    }
});


class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load();
    }
    
    load() {
        this.setState(state => ({
            ...state,
            loading: true
        }), () => this.context.request({
            url: `/manual/sections/${this.props.match.params.sectionid}/subsections/${this.props.match.params.subsectionid}/content`,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            text: this.parseText(response.data.desc, response.attachments),
            title: response.data.name,
            list: response.list.name
        })))
        .catch(() => null))
    }

    parseText(text, attachments) {
        return attachments.reduce((acc, el) => acc.replace(new RegExp("&&"+el.name, 'g'), `<div style="text-align: center !important"><a target="_blank" href='${el.url}'><img style="border: 2px solid #DDD; border-radius: 5px; max-width: 100% !important; margin: 30px 0px;" src='${el.url}' /></a></div>`), text)
    }

    render() {
        const { classes } = this.props;

        return (
        <div className={classes.div}>
            {this.state.loading ? <Loading height={250} /> : (
            <React.Fragment>
                <Breadcrumbs
                    items={[
                        {
                            label: "Temas",
                            link: routes.MANUAL
                        },
                        {
                            label: this.state.list,
                            loading: this.state.loading,
                            link: `${routes.MANUAL}/${this.props.match.params.sectionid}`
                        },
                        {
                            label: this.state.title,
                            loading: this.state.loading,
                            active: true
                        }
                    ]}
                />
                <Typography color="primary" variant="h5">{this.state.title}</Typography>
                <div dangerouslySetInnerHTML={{__html: this.state.text}} />
            </React.Fragment>
            )}
        </div>
        );
    }
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Content.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Content));
