import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OriginalTable from "@material-ui/core/Table";
import classnames from 'classnames';

const styles = theme => ({
    container: {
        maxWidth: "100%",
        overflowX: "auto",
        border: "1px solid rgb(224, 224, 224)",
    },
})

class Table extends Component {
	render() {
        const { classes, height, maxHeight, className, ...other } = this.props;
        const style ={};
        if (height) {
            style.height = height + "px";
        }
        if (maxHeight) {
            style.maxHeight = maxHeight + "px";
        }
		return (
        <div style={style} className={classes.container}>
            <OriginalTable padding="checkbox" {...other} className={classnames({
                [classes.table]: true,
                [className]: className
            })}>
                {this.props.children}
            </OriginalTable>
        </div>
        );
	}
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Table);
