import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OriginalTableCell from "@material-ui/core/TableCell";
import classnames from 'classnames';

const styles = theme => ({
    noWrap: {
        whiteSpace: "nowrap",
    },
    minWidth: {
        width: "1%",
        whiteSpace: "nowrap"
    },
    bold: {
        fontWeight: "bold"
    },
    noPadding: {
        padding: "0px",
        margin: "0px"
    }
})

class TableCell extends Component {
	render() {
        const { classes, className, bold, minWidth, noWrap, noPadding, ...other } = this.props;
        
        return (
        <OriginalTableCell {...other} className={classnames({
            [className]: className,
            [classes.noWrap]: noWrap,
            [classes.minWidth]: minWidth,
            [classes.bold]: bold,
            [classes.noPadding]: noPadding
        })}>
			{this.props.children ? this.props.children : this.props.icon ? <this.props.icon /> : null}
        </OriginalTableCell>
        );
	}
}

TableCell.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(TableCell);
