import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { ListItem, Divider, ListItemText, Avatar, Chip, Typography } from "@material-ui/core";
import Box from "../../utils/Box";
import Title from "../../utils/Title";
import EmptyState from "../../utils/EmptyState";
import context from "../../../context";
import PagedList from "../../utils/PagedList";
import Loading from "../../utils/Loading";
import routes from "../../../routes";
import InboxMultipleIcon from "mdi-react/InboxMultipleIcon";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import CheckIcon from "mdi-react/CheckIcon";
import InboxArrowDownIcon from "mdi-react/InboxArrowDownIcon";
import AttachMoneyIcon from "mdi-react/AttachMoneyIcon";
import { formatAmount, formatMoney } from "../../../utils";

const styles = theme => ({
    chip: {
        marginRight: "3px",
        marginTop: "3px"
    }
});

class SimpleList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        this.load()
    }

    load() {
        this.setState(state => ({
            ...state,
            loading: true,
            data: []
        }), () => this.context.request({
            method: "GET",
            url: "/shoppinglist/simplelist"
        }).then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        }))).catch(err => null))
    }

    render() {
        const { classes } = this.props;
        return (
        <Box>
            <Title>Itens que já estão acabando</Title>
            {this.state.loading ? <Loading height={250} /> : this.state.data.length === 0 ? (
            <EmptyState icon={CheckIcon}>
                Não existem itens acabando
            </EmptyState>
            ) : (
            <PagedList>
                {this.state.data.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem button component={RouterLink} to={routes.REPORT_PRODUCT(item.barcode)}>
                        <ListItemText
                            primaryTypographyProps={{
                                className: classes.primary
                            }}
                            secondaryTypographyProps={{
                                className: classes.secondary
                            }}
                            primary={(
                            <Typography noWrap>
                                <Chip
                                    className={classes.chip}
                                    avatar={<Avatar><BarcodeIcon /></Avatar>}
                                    color="primary"
                                    label={item.barcode}
                                />
                                {item.name}
                            </Typography>
                            )}
                            secondary={(
                            <Typography noWrap>
                                <Chip
                                    className={classes.chip}
                                    avatar={<Avatar><InboxMultipleIcon /></Avatar>}
                                    color={item.warehouse_storage + item.store_storage < 0 ? "secondary" : "primary"}
                                    variant="outlined"
                                    label={`Est. atual: ${formatAmount(item.warehouse_storage + item.store_storage)}`}
                                />
                                {item.minimum_storage > 0 && (
                                    <Chip
                                    className={classes.chip}
                                    avatar={<Avatar><InboxArrowDownIcon /></Avatar>}
                                    variant="outlined"
                                    color="primary"
                                    label={`Est. mín.: ${formatAmount(item.minimum_storage)}`}
                                    />
                                )}
                                {item.cost > 0 && (
                                <Chip
                                    className={classes.chip}
                                    avatar={<Avatar><AttachMoneyIcon /></Avatar>}
                                    variant="outlined"
                                    color="primary"
                                    label={`Últ. valor pago: ${formatMoney(item.cost)}`}
                                />
                                )}
                                
                            </Typography>
                            )}
                        />
                    </ListItem>
                    <Divider component="li" />
                </React.Fragment>
                ))}
            </PagedList>
            )}
        </Box>
        );
    }
}

SimpleList.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

SimpleList.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(SimpleList));
