import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Grid from '@material-ui/core/Grid';
import CashRegisterIcon from 'mdi-react/CashRegisterIcon';
import FileDocumentEditOutlineIcon from 'mdi-react/FileDocumentEditOutlineIcon';
import ShuffleIcon from 'mdi-react/ShuffleIcon';
import { Typography } from "@material-ui/core";
import MapSearchIcon from 'mdi-react/MapSearchIcon';
import ChartAreasplineIcon from 'mdi-react/ChartAreasplineIcon';
import FileImportIcon from 'mdi-react/FileImportIcon';
import FileExportIcon from 'mdi-react/FileExportIcon';
import FileSearchOutlineIcon from 'mdi-react/FileSearchOutlineIcon';
import routes from "../../routes";
import ReportItem from "./ReportItem";

const styles = theme => ({
    section: {
        borderBottom: "1px solid #ccc",
        color: "#aaa",
        fontSize: "20px",
        marginTop: "20px",
        paddingBottom: "10px"
    },
})

class Reports extends Component {

    categories = [
        {
            title: "Relatórios gerais",
            reports: [
                {
                    title: "Fechamento de caixa",
                    icon: CashRegisterIcon,
                    path: routes.REPORT_CASH_CONTROL,
                },
                {
                    title: "Resumo de vendas",
                    icon: ChartAreasplineIcon,
                    path: routes.REPORT_SALES_SUMMARY
                }
            ]
        },
        {
            title: "Pesquisas",
            reports: [
                {
                    title: "Documentos e cupons",
                    icon: FileSearchOutlineIcon,
                    path: routes.REPORT_DOCUMENTS,
                },
                {
                    title: "Catálogo de produtos",
                    icon: MapSearchIcon,
                    path: routes.REPORT_PRODUCT_LIST,
                },
            ]
        },
        {
            title: "Outros relatórios",
            reports: [
                {
                    title: "Fluxo de caixa",
                    icon: ShuffleIcon,
                    path: routes.REPORT_CASHBOOK_FLOW
                },
                {
                    title: "Contas à pagar",
                    icon: FileExportIcon,
                    path: routes.REPORT_BILLS_TO_PAY
                },
                {
                    title: "Contas à receber",
                    icon: FileImportIcon,
                    path: routes.REPORT_BILLS_TO_RECEIVE
                },
                {
                    title: "Lista de compras",
                    icon: FileDocumentEditOutlineIcon,
                    path: routes.REPORT_SHOPPING_LIST,
                }
            ]
        },
    ];

    render() {
        const { classes } = this.props;
		return (
        <Grid container spacing={24}>
            {this.categories.map((categorie, cat_id) => (
            <Fragment key={cat_id}>
                <Grid item xs={12}>
                    <Typography className={classes.section}>
                        {categorie.title}
                    </Typography>
                </Grid>
                {categorie.reports.map((report, rep_id) => (
                    <ReportItem
                        key={rep_id}
                        title={report.title}
                        icon={report.icon}
                        path={report.path}
                    />
                ))}
            </Fragment>
            ))}
        </Grid>
		);
    }
}

Reports.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Reports.contextType = context;

export default withStyles(styles, {withTheme: true})(Reports);
