import React, { Component } from "react";
import PropTypes from 'prop-types';
import context from "../../../context";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Subtitle from "../../utils/Subtitle";
import Loading from "../../utils/Loading";
import { paymentTypes, formatMoney } from "../../../utils";
import LineChart from "../../utils/LineChart";
import PieChart from '../../utils/PieChart';
import Table from '../../utils/Table';
import TableCell from '../../utils/TableCell';
import {TableHead, TableBody, TableRow} from '@material-ui/core';
import SigmaIcon from 'mdi-react/SigmaIcon';

class PaymentMethod extends Component {

    constructor(props) {
        super(props);
        this.state = {
			data: null,
			loading: true
		};
    }


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.match.params.from !== this.props.match.params.from ||
            nextProps.match.params.to !== this.props.match.params.to ||
            nextProps.match.params.groupby !== this.props.match.params.groupby
        ) {
            this.load(
                nextProps.match.params.from,
                nextProps.match.params.to,
                nextProps.match.params.groupby
            );
        }
    }

    componentDidMount() {
        this.load(
            this.props.match.params.from,
            this.props.match.params.to,
            this.props.match.params.groupby
        );
    }

    load(from, to, groupby) {
        this.setState(state => ({
			...state,
			loading: true,
			data: null
        }), () => this.context.request({
            url: `/salessummary/paymentmethod/${from}/${to}/${groupby}`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
			loading: false,
			data: response
        })))
        .catch(error => null))
    }

    render() {
		return (
        <Box>
            <Subtitle>Vendas por forma de pagamento</Subtitle>
            {this.state.loading ? <Loading /> : (
			<div>	
				<PieChart
					data={this.state.data.methods.map(method => ({
						name: method.description,
						value: method.emitted + method.nonemitted 
					}))}
					dataKey="value"
					nameKey="name"
				/>
				<LineChart
					onlyPositive
					xValues={this.state.data.date}
					dataSet={this.state.data.methods.map(method => ({
						legend: method.description,
						values: method.data.map(e => e.e + e.n)
					}))}
				/>
				<Table height={250}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2}>Forma de pagamento</TableCell>
							<TableCell>Autorizado</TableCell>
							<TableCell>Não autorizado</TableCell>
							<TableCell>Total</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.data.methods.map((method, index) => (
						<TableRow key={index}>
							<TableCell style={{ color: "#777" }} minWidth icon={paymentTypes.find(t => t.id === method.type).icon} />
							<TableCell noWrap bold>
								{method.description}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(method.emitted)}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(method.nonemitted)}
							</TableCell>
							<TableCell noWrap>
								{formatMoney(method.emitted + method.nonemitted)}
							</TableCell>
						</TableRow>
						))}
						<TableRow>
							<TableCell style={{ color: "#777" }} minWidth icon={SigmaIcon} />
							<TableCell noWrap bold>
								Total
							</TableCell>
							<TableCell noWrap bold>
								{formatMoney(this.state.data.methods.reduce((tot, method) => tot+method.emitted, 0))}
							</TableCell>
							<TableCell noWrap bold>
								{formatMoney(this.state.data.methods.reduce((tot, method) => tot+method.nonemitted, 0))}
							</TableCell>
							<TableCell noWrap bold>
								{formatMoney(this.state.data.methods.reduce((tot, method) => tot+method.emitted+method.nonemitted, 0))}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
            )}
        </Box>
        );
    }
}

PaymentMethod.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

PaymentMethod.contextType = context;

export default withRouter(PaymentMethod);
