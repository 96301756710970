import React, { Component } from "react";
import PaymentMethodPerType from './PaymentMethodPerType';
import PaymentMethod from './PaymentMethod';
import ServiceTax from './ServiceTax';
import Couvert from './Couvert';
import Profit from './Profit';
import Title from "../../utils/Title";
import { FormControl, TextField, RadioGroup, FormControlLabel, Radio, Grid, Button } from "@material-ui/core";
import { getTodaysDate } from "../../../utils";
import routes from "../../../routes";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import context from "../../../context";
import PerHour from './PerHour';

class SalesSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            from: props.match.params.from || getTodaysDate(-14),
            to: props.match.params.to || getTodaysDate(),
            groupby: props.match.params.groupby || 'day'
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }

    search() {
        if (this.state.from && this.state.to && this.state.groupby) {
            this.context.pushHistory(`${routes.REPORT_SALES_SUMMARY}/${this.state.from}/${this.state.to}/${this.state.groupby}`)
        }
    }

    render() {
        return (
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <Box>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Title>Resumo de vendas</Title>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="De"
                                    name="from"
                                    type="date"
                                    value={this.state.from}
                                    onChange={this.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Até"
                                    name="to"
                                    type="date"
                                    value={this.state.to}
                                    onChange={this.onChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <RadioGroup
                                    name="groupby"
                                    value={this.state.groupby}
                                    onChange={this.onChange}
                                    row
                                >
                                    <FormControlLabel value="day" control={<Radio color="primary" />} label="agrupar datas por dia" />
                                    <FormControlLabel value="month" control={<Radio color="primary" />} label="agrupar datas por mês" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size="large" fullWidth onClick={() => this.search()} variant="contained" color="primary">
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {!(this.props.match.params.groupby && this.props.match.params.from && this.props.match.params.to) ?  null : (
			<React.Fragment>	
				<Grid item xs={12} md={6}><PaymentMethodPerType /></Grid>
				<Grid item xs={12} md={6}><PaymentMethod /></Grid>
				<Grid item xs={12} md={6}><Profit /></Grid>
				<Grid item xs={12} md={6}><ServiceTax /></Grid>
				<Grid item xs={12} md={6}><Couvert /></Grid>
				<Grid item xs={12} md={6}><PerHour /></Grid>
			</React.Fragment>
            )}
        </Grid>
        );
    }
}

SalesSummary.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};


SalesSummary.contextType = context;


export default withRouter(SalesSummary);
