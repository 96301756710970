import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Sidebar from "./Sidebar";
import MenuIcon from 'mdi-react/MenuIcon';
import Routes from "./Routes";
import Profile from "./Profile";
import context from "../../context";
import WarningIcon from 'mdi-react/WarningIcon'
import Enterprises from "../session/Enterprises";


const drawerWidth = 250;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            display: "none"
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        overflowX: "hidden",
        flexGrow: 1,
        paddingTop: 3*theme.spacing.unit,
        paddingBottom: "150px",
        [theme.breakpoints.up("xs")]: {
            paddingLeft: theme.spacing.unit,
            paddingRight: theme.spacing.unit
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: 2*theme.spacing.unit,
            paddingRight: 2*theme.spacing.unit
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 3*theme.spacing.unit,
            paddingRight: 3*theme.spacing.unit,
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        },
    },
});


class Nav extends Component {
    state = {
        open: false,
        profile: {
            loading: true,
            name: '',
            cnpj: ''
        },
        sidebar: {
            loading: true,
            admin: false
        },
        version: {
            loading: true,
            server: 0,
            sync: 0
        },
        enterprises: {
            list: [],
            loading: false
        }
    }

    clearEnterprises = () => this.setState(state => ({
        ...state,
        enterprises: {
            ...state.enterprises,
            list: [],
            loading: false
        }
    }))

    selectEnterprise = cnpj => this.setState(state => ({
        ...state,
        profile: {
            ...state.profile,
            loading: true
        }
    }), () => this.context.request({
        url: "/session/changeenterprise",
        method: "POST",
        body: { cnpj }
    }).then(response => {
        localStorage.setItem("token", response.token);
        window.location.reload();
    }).catch(() => this.setState(state => ({
        ...state,
        profile: {
            ...state.profile,
            loading: false
        }
    }))))

    loadEnterprises = () => this.setState(state => ({
        ...state,
        enterprises: {
            ...state.enterprises,
            loading: true,
            list: []
        }
    }), () => this.context.request({
        url: "/session/enterprises",
        method: "GET"
    }).then(response => this.setState(state => ({
        ...state,
        enterprises: {
            ...state.enterprises,
            loading: false,
            list: response
        }
    }))).catch(() => this.setState(state => ({
        ...state,
        enterprises: {
            ...state.enterprises,
            loading: false,
            list: []
        }
    }))))


    componentDidMount() {
        this.context.request({
            url: "/nav/admin",
            method: "GET",
        })
        .then(response => this.setState(state => ({
            ...state,
            sidebar: {
                ...state.sidebar,
                loading: false,
                admin: response.admin
            }
        })))
        .catch(() => this.setState(state => ({
            ...state,
            sidebar: {
                ...state.sidebar,
                loading: false,
                admin: false
            }
        })));

        this.context.request({
            url: "/nav/profile",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            profile: {
                ...state.profile,
                loading: false,
                name: response.name,
                cnpj: response.cnpj
            }
        })))
        .catch(() => null);

        this.context.request({
            url: "/nav/version",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            version: {
                ...state.version,
                loading: false,
                sync: response.sync,
                server: response.server
            }
        }),))
        .catch(() => null);
    }

    switchNavbar = () => {
        this.setState(state => ({
            ...state,
            open: !state.open
        }))
    }

	render() {
        const { classes } = this.props

		return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={this.switchNavbar}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" noWrap>
                        Click Web 
                    </Typography>
                    {this.state.version.server <= this.state.version.sync ? null : (
                    <Tooltip title="Seu sistema está desatualizado!">
                        <WarningIcon
                            style={{
                                marginLeft: "10px"
                            }}
                            color="#e57373"
                            size="30px"
                        />
                    </Tooltip>
                    )}
                </Toolbar>
            </AppBar>
            <nav>
                <Hidden mdUp implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="temporary"
                        open={this.state.open}
                        onClose={this.switchNavbar}
                    >
                        <Profile
                            loading={this.state.profile.loading}
                            loadingEnterprises={this.state.enterprises.loading}
                            name={this.state.profile.name}
                            cnpj={this.state.profile.cnpj}
                            loadEnterprises={this.loadEnterprises}
                        />
                        <Divider />
                        <Sidebar
                            loading={this.state.sidebar.loading}
                            admin={this.state.sidebar.admin}
                            onClose={this.switchNavbar}
                        />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                    >
                        <Profile
                            loading={this.state.profile.loading}
                            loadingEnterprises={this.state.enterprises.loading}
                            name={this.state.profile.name}
                            cnpj={this.state.profile.cnpj}
                            loadEnterprises={this.loadEnterprises}
                        />
                        <Divider />
                        <Sidebar
                            loading={this.state.sidebar.loading}
                            admin={this.state.sidebar.admin}
                        />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Routes />
            </main>
            <Enterprises
                close={this.clearEnterprises}
                open={this.state.enterprises.list.length !== 0}
                enterprises={this.state.enterprises.list}
                selectEnterprise={this.selectEnterprise}
            />
        </div>
		);
	}
}

Nav.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Nav.contextType = context;

export default withStyles(styles, {withTheme: true})(Nav);
