import React, { Component } from "react";
import PropTypes from 'prop-types';
import context from "../../../context";
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Subtitle from "../../utils/Subtitle";
import Loading from "../../utils/Loading";
import { formatMoney, formatAmount } from "../../../utils";
import LineChart from "../../utils/LineChart";
import Table from '../../utils/Table';
import TableCell from '../../utils/TableCell';
import {TableHead, TableBody, TableRow} from '@material-ui/core';
import BarChart from '../../utils/BarChart';

class PerHour extends Component {

    constructor(props) {
        super(props);
        this.state = {
			data: null,
			loading: true
		};
    }


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.match.params.from !== this.props.match.params.from ||
            nextProps.match.params.to !== this.props.match.params.to ||
            nextProps.match.params.groupby !== this.props.match.params.groupby
        ) {
            this.load(
                nextProps.match.params.from,
                nextProps.match.params.to,
                nextProps.match.params.groupby
            );
        }
    }

    componentDidMount() {
        this.load(
            this.props.match.params.from,
            this.props.match.params.to,
            this.props.match.params.groupby
        );
    }

    load(from, to, groupby) {
        this.setState(state => ({
			...state,
			loading: true,
			data: null
        }), () => this.context.request({
            url: `/salessummary/perhour/${from}/${to}`,
            method: "GET"
        }).then(response => this.setState(state => ({
            ...state,
			loading: false,
			data: response
        })))
        .catch(error => null))
    }


    render() {
		return (
        <Box>
			<Subtitle>Vendas por hora</Subtitle>
			{this.state.loading ? <Loading /> : (
			<div>
				<LineChart
					onlyPositive
					xValues={this.state.data.map((v, h) => `${h}:00 ~ ${h}:59`)}
					dataSet={[
						{
							legend: "Média de venda/sdia",
							values: this.state.data.map(el => el.perday)
						},
						{
							legend: "Média de valor/documento",
							values: this.state.data.map(el => el.perdocument)
						}
					]}
				/>
				<BarChart
					onlyPositive
					amount
					xValues={this.state.data.map((v, h) => `${h}:00 ~ ${h}:59`)}
					dataSet={[{
						legend: "Média de documentos/dia",
						values: this.state.data.map(el => el.documentsperday)
					}]}
				/>

				<Table height={250}>
					<TableHead>
						<TableRow>
							<TableCell>Hora</TableCell>
							<TableCell>Média de<br />documentos por dia</TableCell>
							<TableCell>Média de<br />valor por documento</TableCell>
							<TableCell>Média de<br />venda por dia</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.data.map((value, index) => (
						<TableRow key={index}>
							<TableCell noWrap bold>{`${index}:00 ~ ${index}:59`}</TableCell>
							<TableCell noWrap>{formatAmount(value.documentsperday)}</TableCell>
							<TableCell noWrap>{formatMoney(value.perday)}</TableCell>
							<TableCell noWrap>{formatMoney(value.perday)}</TableCell>
						</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
            )}
        </Box>
        );
    }
}

PerHour.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

PerHour.contextType = context;

export default withRouter(PerHour);
