import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class LoadingButton extends React.Component {
    render() {
        const { classes, loading, ...other } = this.props;
        return (
        <div className={classes.wrapper}>
            <Button
                {...other}
                fullWidth
                disabled={loading || this.props.disabled}
            >
                {this.props.children}
            </Button>
            {loading && <CircularProgress disableShrink size={24} className={classes.buttonProgress} />}
        </div> 
        );
    }
}

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LoadingButton);