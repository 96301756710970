import React, { Component } from "react";
import context from "../../../context";
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, ListItemAvatar, Avatar, Chip, Divider, Typography } from "@material-ui/core";
import { formatMoney, formatDateTime } from "../../../utils";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import LoginIcon from "mdi-react/LoginIcon";
import ReceiptIcon from "mdi-react/ReceiptIcon";
import LogoutIcon from "mdi-react/LogoutIcon";
import {Link as RouterLink} from 'react-router-dom';
import routes from "../../../routes";
import QrcodeIcon from "mdi-react/QrcodeIcon";
import DeleteForeverIcon from "mdi-react/DeleteForeverIcon";
import CheckIcon from "mdi-react/CheckIcon";

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    avatarCanceled: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    chip: {
        marginLeft: "5px",
        marginBottom: "5px"
    },
});


class DocumentListItem extends Component {

    render() {
        const { classes } = this.props;
        return (
        <React.Fragment>
            <ListItem button component={RouterLink} to={`${routes.REPORT_DOCUMENTS}/document/${this.props.id}`}>
                <ListItemAvatar>
                    <Avatar className={this.props.canceled ? classes.avatarCanceled : classes.avatar}>
                        {this.props.emitted ? <QrcodeIcon /> : this.props.canceled ? <DeleteForeverIcon /> : <CheckIcon />}
                    </Avatar>
                </ListItemAvatar>  
                <ListItemText
                    primary={<Typography noWrap>
                        <Chip
                            className={classes.chip} 
                            color="primary"
                            variant="outlined"
                            label={`Documento ${this.props.id}`}
                            avatar={<Avatar><ReceiptIcon /></Avatar>}
                        />
                        <Chip
                            className={classes.chip}
                            color="primary"
                            variant="outlined"
                            label={`${formatMoney(this.props.value)}`}
                            avatar={<Avatar><CurrencyUsdIcon /></Avatar>}
                        />
                    </Typography>}
                    secondary={(
                    <Typography noWrap>
                        {!this.props.register_date ? null : <Chip
                            className={classes.chip} 
                            color="primary"
                            avatar={<Avatar><LoginIcon /></Avatar>}
                            label={formatDateTime(this.props.register_date)}
                        />}
                        {!this.props.closure_date ? null : <Chip
                            className={classes.chip} 
                            color="primary"
                            avatar={<Avatar><LogoutIcon /></Avatar>}
                            label={formatDateTime(this.props.closure_date)}
                        />}
                        {!this.props.cancel_date ? null : <Chip
                            className={classes.chip} 
                            color="secondary"
                            avatar={<Avatar><DeleteForeverIcon /></Avatar>}
                            label={formatDateTime(this.props.cancel_date)}
                        />}
                    </Typography>
                    )}
                />
            </ListItem>
            <Divider component="li" />
        </React.Fragment>
        );
    }

}

DocumentListItem.contextType = context;

export default withStyles(styles, {withTheme: true})(DocumentListItem);