import React, { Component } from "react";
import context from "../../../context";
import Loading from "../../utils/Loading";
import { withStyles } from '@material-ui/core/styles';
import EmoticonSadOutlineIcon from 'mdi-react/EmoticonSadOutlineIcon';
import Title from "../../utils/Title";
import EmptyState from "../../utils/EmptyState";
import DocumentListItem from "./DocumentListItem";
import PagedList from "../../utils/PagedList";

const styles = theme => ({

});


class Cashcontrol extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.load(nextProps.match.params.id);
        }
    }


    load(id) {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: `/documents/cashcontrol/${id}`,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        }))).catch(() => null))
    }

    render() {
        return this.state.loading ? (
        <Loading height={300} />
        ) : this.state.data.length === 0 ? (
        <EmptyState icon={EmoticonSadOutlineIcon}>
            Controle de caixa não encontrado ou vazio
        </EmptyState>
        ) : (
        <div>
            <Title>Documentos do controle de caixa {this.props.match.params.id}</Title>
            <PagedList>
                {this.state.data.map((document, index) => <DocumentListItem
                    key={index}
                    {...document}
                />)}
            </PagedList>
        </div>
        );
    }

}

Cashcontrol.contextType = context;

export default withStyles(styles, {withTheme: true})(Cashcontrol);