import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    tableCell: {
        whiteSpace: "nowrap",
        width: "1%",
        padding: "0px",
        minWidth: "1%",
        margin: "0px"
    },
})

class TableCellButton extends Component {
	render() {
        const { classes, icon, tooltip, onClick, href, target, disabled, ...others } = this.props;
		return (
        <TableCell {...others} className={classes.tableCell}>
            <Tooltip title={tooltip} placement="top">
                <div>
                    <IconButton
                        component={href ? "a" : "button"}
                        href={href}
                        target={target}
                        color="primary"
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <this.props.icon />
                    </IconButton>
                </div>
            </Tooltip>
        </TableCell>
        );
	}
}

TableCellButton.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(TableCellButton);
