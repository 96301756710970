import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from 'react-router-dom'
import Enterprises from "./Enterprises";
import LoadingButton from "../utils/LoadingButton";
import context from "../../context";
import routes from "../../routes";

const styles = theme => ({});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            enterprises: []
        }
        this.onChange = this.onChange.bind(this);
        this.getEnterprises = this.getEnterprises.bind(this);
        this.selectEnterprise = this.selectEnterprise.bind(this);
        this.clearEnterprises = this.clearEnterprises.bind(this);
    }

    onChange(e) {
		e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }

    clearEnterprises() {
		this.setState(state => ({
			...state,
			enterprises: []
		}));
	}

    getEnterprises() {
		this.setState(state => ({
			...state,
			loading: true
		}), () => this.context.request({
			url: "/session/enterprises",
			method: "POST",
			body: {
				email: this.state.email,
				password: this.state.password
			}
		})
		.then(response => this.setState(state => ({
			...state,
			loading: false,
			enterprises: response
		})))
		.catch(() => this.setState(state => ({
            ...state,
            loading: false
        }))))
	}
    
    selectEnterprise(cnpj) {
		this.setState(state => ({
			...state,
			loading: true,
			enterprises: []
		}), () => this.context.request({
			url: "/session/login",
			method: "POST",
			body: {
				email: this.state.email,
				password: this.state.password,
				cnpj: cnpj
			}
		})
		.then(response => this.setState(state => ({
			...state,
			loading: false,
		}), () => {
			localStorage.setItem("token", response.token);
			this.context.pushHistory(routes.NAV);
		}))
		.catch(() => this.setState(state => ({
			...state,
			loading: false,
		}))))
	}

	render() {
		return (
        <form style={{padding: "15px"}}>
            <Typography variant="h5" color="primary">
                Conectar-se
            </Typography>
            <FormControl fullWidth={true} margin="dense">
                <TextField
                    label="E-mail"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                />
            </FormControl>
            <FormControl fullWidth={true} margin="dense">
                <TextField
                    label="Senha"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChange}
                />
            </FormControl>
            <FormControl fullWidth={true} margin="dense">
                <Link component={RouterLink} to={routes.SEND_PASSWORD_RECOVER}>
                    Esqueceu sua senha?
                </Link>
            </FormControl>
            <FormControl fullWidth={true} margin="dense">
                <LoadingButton
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={this.getEnterprises}
                    loading={this.state.loading}
                >
                    Entrar
                </LoadingButton>
            </FormControl>
            <Enterprises
                close={this.clearEnterprises}
                open={this.state.enterprises.length !== 0}
                enterprises={this.state.enterprises}
                selectEnterprise={this.selectEnterprise}
            />
        </form>
		);
	}
}

Login.contextType = context;

export default withStyles(styles, { withTheme: true } )(Login);
