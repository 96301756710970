import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../context";
import Typography from '@material-ui/core/Typography';
import Box from "../utils/Box";
import Loading from "../utils/Loading";
import { formatDateTime } from "../../utils";
import { Divider } from "@material-ui/core";


const styles = theme => ({
    label: {
        textAlign: "left",
        fontWeight: "bold",
        color: "#757575",
        textTransform: "uppercase",
        fontSize: "10px",
        textOverflow: "ellipsis",
        overflow: "none",
        whiteSpace: "nowrap",
        maxWidth: "100%",
        marginTop: "20px"
    },
    value: {
        textAlign: "right",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#616161",
        backgroundImage: 'linear-gradient(to left, '+theme.palette.primary.main+'33, transparent)',
        paddingRight: "3px",
    },
    content: {
        height: "180px",
        overflowY: "auto"
    },
    box: {
        padding: "5px"
    }
})


class Sync extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null
        }
        this.load = this.load.bind(this);
    }

    componentDidMount() {
        this.load()
    }

    load() {
        this.setState(state => ({
            ...state,
            loading: true,
        }), () => this.context.request({
            url: "/dashboard/sync",
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

	render() {

        const { classes } = this.props

		return (
        <Box>
            <Typography gutterBottom variant="h5" color="primary">Sincronização</Typography>
            <Divider/>
            {this.state.loading ? <Loading height={150} /> : (
            <div className={classes.content}>
                <Typography className={classes.label}>Última sincronização</Typography>
                <Typography className={classes.value}>{this.state.data.last_sync_date ? formatDateTime(this.state.data.last_sync_date) : "Nunca sincronizado"}</Typography>
                <Typography className={classes.label}>Último caixa sincronizado</Typography>
                <Typography className={classes.value}>{this.state.data.last_control_id ? this.state.data.last_control_id : "Nunca sincronizado"}</Typography>
                <Typography className={classes.label}>Fechamento do último caixa</Typography>
                <Typography className={classes.value}>{this.state.data.last_closure_date ? formatDateTime(this.state.data.last_closure_date) : "Nunca sincronizado"}</Typography>
            </div>
            )}
        </Box>
		);
    }
}

Sync.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Sync.contextType = context;

export default withStyles(styles, {withTheme: true})(Sync);
