import React, { Component, Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Title from "../../utils/Title";
import EmptyState from "../../utils/EmptyState";
import CheckIcon from "mdi-react/CheckIcon";
import { Table, TableHead, TableBody, TableRow, TableCell, FormControl, TextField, Button  } from "@material-ui/core";
import Loading from "../../utils/Loading";
import { formatDate, formatMoney, formatDateTime, getTodaysDate } from "../../../utils";
import queryString from 'query-string';

const styles = theme => ({
})

class BillsToPay extends Component {


    constructor(props) {
        super(props);
        const query = queryString.parse(props.location.search);
        this.state = {
            loading: true,
            data: {},
            from: query.from ? query.from : getTodaysDate(-7),
            to: query.to ? query.to : getTodaysDate(),
            selected_from: query.from ? query.from : getTodaysDate(-7),
            selected_to: query.to ? query.to : getTodaysDate(),
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.load(this.state.selected_from, this.state.selected_to);
    }

    onChange(e) {
        e.persist();
		this.setState(state => ({
			...state,
            [e.target.name]: e.target.value
		}));
    }
    
    load(from, to) {
        this.setState(state => ({
            ...state,
            loading: true,
            selected_from: from,
            selected_to: to,
        }), () => this.context.request({
            url: "/billstopay/" + from + "/" + to,
            method: "GET"
        })
        .then(response => this.setState(state => ({
            ...state,
            loading: false,
            data: response
        })))
        .catch(() => null))
    }

    search() {
        if (this.state.from && this.state.to) {
            this.load(this.state.from, this.state.to);
        } else {
            this.context.pushMessage("Insira um intervalo de data válido");
        }
    }

    render() {
        return (
        <Grid container justify={"center"} spacing={24}>
            <Grid item xs={12} xl={10}>
                <Box>
                    <Title>Contas a pagar</Title>
                    {this.state.loading ? <Loading height={250} /> :
                    <Fragment>
                        <FormControl margin="dense" fullWidth>
                            <TextField
                                label={"De"}
                                name={"from"}
                                type={"date"}
                                value={this.state.from}
                                onChange={this.onChange}
                            />
                        </FormControl>
                        <FormControl margin="dense" fullWidth>
                            <TextField
                                label={"Até"}
                                name={"to"}
                                type={"date"}
                                value={this.state.to}
                                onChange={this.onChange}
                            />
                        </FormControl>
                        <FormControl margin="dense" fullWidth>
                            <Button
                                onClick={() => this.search()}
                                variant="contained"
                                color="primary"
                            >
                                Buscar
                            </Button>
                        </FormControl>
                        {this.state.data.length === 0 ? <EmptyState icon={CheckIcon}>Não existem contas à pagar neste período</EmptyState> : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Vencimento</TableCell>
                                    <TableCell>Pagamento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data.map((el, index) => (
                                <TableRow key={index}>
                                    <TableCell>{el.description}</TableCell>
                                    <TableCell>{formatMoney(el.value)}</TableCell>
                                    <TableCell>{formatDate(el.maturity)}</TableCell>
                                    <TableCell>{el.paid ? formatDateTime(el.payment_date) + " (" + formatMoney(el.paid_value) + ")" : "NÃO PAGO"}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        )}
                    </Fragment>
                    }
                </Box>
            </Grid>
        </Grid>
        );
    }
}

BillsToPay.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

BillsToPay.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(BillsToPay));
