import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import Box from "../../utils/Box";
import Title from "../../utils/Title";
import TimeSeries from "../../utils/TimeSeries";
import LineChart from "../../utils/LineChart";
import { withRouter } from 'react-router-dom';


const styles = theme => ({
})

class Sales extends Component {
    render() {
        return (
        <Grid item xs={12} lg={6}>
            <Box>
                <Title>Vendas</Title>
                <TimeSeries
                    url={(from, to, group) => `/product/sales/${this.props.barcode}/${from}/${to}/${group}`}
                >
                    {data => <LineChart
                        xValues={data.map(item => item.date)}
                        dataSet={[
                            {
                                legend: "Vendas",
                                values: data.map(item => item.value)
                            },
                        ]}
                    />}
                </TimeSeries>
            </Box>
        </Grid>
        );
    }
}

Sales.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

Sales.contextType = context;

export default withStyles(styles, {withTheme: true})(withRouter(Sales));
