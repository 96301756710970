import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import context from "../../../context";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from "../../utils/Box";
import { withRouter } from 'react-router-dom';
import Title from "../../utils/Title";
import routes from "../../../routes";
import { getTodaysDate } from "../../../utils";
import { FormControl, TextField, Button } from "@material-ui/core";
import Header from "./Header";
import Payments from "./Payments";
import Receivements from "./Receivements";
import Depletions from "./Depletions";
import ServiceTax from "./ServiceTax";
import Couvert from "./Couvert";
import CanceledItems from "./CanceledItems";
import CanceledDocuments from "./CanceledDocuments";
import Totalizers from "./Totalizers";
import SalesPerPaymentMethod from "./SalesPerPaymentMethod";
import SalesPerProduct from "./SalesPerProduct";
import Documents from "./Documents";
import Controls from "./Controls";

class CashControl extends Component {

	constructor(props) {
		super(props);
		this.state = {
			from: props.match.params.from || getTodaysDate(-7),
			to: props.match.params.to || getTodaysDate(),
		}
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		e.persist();
		this.setState(state => ({
			...state,
			[e.target.name]: e.target.value
		}));
	}

	search() {
		if (this.state.from && this.state.to) {
			this.context.pushHistory(`${routes.REPORT_CASH_CONTROL}/${this.state.from}/${this.state.to}`);
		} else {
			this.context.pushMessage("Insira um intervalo de data válido");
		}
	}

	render() {
		return (
		<Grid container spacing={24}>
			<Grid item xs={12}>
				<Box>
					<Grid container spacing={24}>
						<Grid item xs={12}><Title>Fechamento de caixa</Title></Grid>
						<Grid item xs={12} sm={5}>
							<Typography color="primary" variant="h6" gutterBottom>Escolha um intervalo de data</Typography>
							<Typography paragraph>
								Será pesquisado controles de caixas que foram abertos durante o intervalo de tempo selecionado abaixo.
							</Typography>
							<FormControl margin="dense" fullWidth>
								<TextField
									label={"De"}
									name={"from"}
									type={"date"}
									value={this.state.from}
									onChange={this.onChange}
								/>
							</FormControl>
							<FormControl margin="dense" fullWidth>
								<TextField
									label={"Até"}
									name={"to"}
									type={"date"}
									value={this.state.to}
									onChange={this.onChange}
								/>
							</FormControl>
							<FormControl margin="dense" fullWidth>
								<Button

									onClick={() => this.search()}
									variant="contained"
									color="primary"
								>
									Buscar caixas
								</Button>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={7}><Controls /></Grid>
					</Grid>
				</Box>
			</Grid>
			{!this.props.match.params.id || (
			<Fragment>
				<Grid item xs={12} lg={6}><Header /></Grid>
				<Grid item xs={12} lg={6}><Totalizers /></Grid>
				<Grid item xs={12}><SalesPerPaymentMethod /></Grid>
				<Grid item xs={12} lg={6}><Documents /></Grid>
				<Grid item xs={12} lg={6}><Depletions /></Grid>
				<Grid item xs={12} lg={12}><SalesPerProduct /></Grid>
				<Grid item xs={12} lg={12}><Payments /></Grid>
				<Grid item xs={12} lg={12}><Receivements /></Grid>
				<Grid item xs={12} sm={6} lg={6}><ServiceTax /></Grid>
				<Grid item xs={12} sm={6} lg={6}><Couvert /></Grid>
				<Grid item xs={12} lg={12}><CanceledDocuments /></Grid>
				<Grid item xs={12} lg={12}><CanceledItems /></Grid>
			</Fragment>
			)}
		</Grid>
		);
	}
}

CashControl.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

CashControl.contextType = context;

export default withRouter(CashControl);
